import React from "react";
import "./TowBarPage.css";
import Header from "../../components/header/Header";
import TowBarPageContent from "../../components/towBarPageContent/TowBarPageContent";

const TowBarPage = () => {
  return (
    <div className="TowBarPage">
      <Header />
      <TowBarPageContent />
    </div>
  );
};

export default TowBarPage;
