import React from "react";
import "./EventsIntro.css";
import EventsImage from "../../assets/Events.jpg";

const EventsIntro = () => {
  return (
    <div>
      <img alt="hero" className="EventsIntro_Hero" src={EventsImage} />
      <div className="EventsIntro_Hero_Layer"></div>
      <div className="EventsIntroWrapper">
        <div className="EventsIntro">
          <h1>PASĀKUMI</h1>
          <div className="EventsIntroContent">
          <div className="EventsIntroContentBorderTop"></div>
          <div className="EventsIntroContentText">
            <h3>
              Doles sala ir dzīva un pulsējoša ar dabas piedzīvojumiem un
              kultūras un izklaides pasākumiem, kas gaida jūs visu cauru gadu.
            </h3>
            <h3>
              Vai jūs meklējat mūzikas koncertus, meistarklases, mākslas
              izstādes vai dabas piedzīvojumus? Doles sala piedāvā to visu un
              vēl daudz ko citu!
            </h3>
            <h3>
              Neskatoties uz sezonu, Doles sala ir piepildīta ar pasākumiem
              visām gaumēm. Izbaudiet siltās vasaras vakarus, sildoties pie
              ugunskura un klausoties dziesmas zem zvaigznēm. Iegrimstiet
              radošajā atmosfērā mākslas darbnīcās un iepazīstiet vietējo
              mājražotāju veikumu. Vai esat piedzīvojuma meklētājs? Dodieties
              pastaigā pa ainavisko dabu, piedalieties orientēšanās sacensībās
              vai izbaudiet ziemas sportu aktivitātes.
            </h3>
            <h3>
              Doles sala ir vieta, kur sastapties ar cilvēkiem, kas ir kā jūs -
              ceļotāji, meklētāji un dzīves baudītāji. Pasākumu saraksts
              pastāvīgi atjauninās, tāpēc regulāri apmeklējiet šo lapu, lai
              uzzinātu par jauniem piedzīvojumiem, kas gaida jūs Doles salā.
            </h3>
          </div>
          <div className="EventsIntroContentBorderBottom"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsIntro;
