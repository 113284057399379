import React from "react";
import "./CommunityValuesSelf.css";

const CommunityValuesSelf = () => {
  return (
    <>
      <div className="CommunityValuesSelf">
        <div>
          <div className="CommunityValuesSelfHeading">
            <h2>PAŠPIETIEKAMA KOPIENA</h2>
          </div>
          <p>
            Doles sala, kuras ainava un sabiedriskā dzīve ir piesātināta ar
            dabas un kultūras bagātību, izceļas arī ar vienu no svarīgākajām
            vērtībām - pašpietiekamību. Pašpietiekama kopiena Doles salā
            izpaužas daudzveidīgā veidā. Pirmais aspekts ir lauksaimniecība un
            pārtikas ražošana. Iedzīvotāji iespēju robežās veido ilgtspējīgas
            saimniecības, kurās tiek audzētas dārzeņi, augļi un ganības, kas
            nodrošina pietiekami daudz pārtikas vietējiem iedzīvotājiem. Šāds
            pašpietiekams pārtikas avots palīdz samazināt atkarību no
            importētiem produktiem un veicina veselīgu un ilgtspējīgu uzturu.
          </p>
        </div>
        <div className="CommunityValuesSelfImage">
          <img
            src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/values_paspiet.png"
            alt="Self-sufficient community"
          />
        </div>
        <div>
          <p>
            Otrs aspekts ir enerģijas pašpietiekamība. Doles salas kopienas ir
            aktīvi iesaistījušās atjaunojamās enerģijas avotu izmantošanā,
            piemēram, saules enerģijā. Tas ne tikai palīdz samazināt kaitīgo
            ietekmi uz apkārtējo vidi, bet arī nodrošina energoapgādi kopienai
            patstāvīgi.
          </p>
        </div>
        <div>
          <p>
            Turklāt Doles sala ir attīstījusi pašpietiekamus risinājumus ūdens
            apgādes un atkritumu pārvaldības jomās. Mājokļi un sabiedriskās ēkas
            ir aprīkotas ar ūdens attīrīšanas iekārtām un ieviesta
            atkritumu šķirošana, kas veicina ilgtspējīgu resursu izmantošanu.
          </p>
        </div>
        <div>
          <p>
            Pašpietiekamība Doles salā ir arī kultūras un sabiedriskās dzīves
            sastāvdaļa. Kopienas dalībnieki organizē kopīgus pasākumus, lai veicinātu
            savstarpējo atkarību un solidaritāti. Šie aspekti kopumā raksturo
            Doles salu kā vietu, kur pašpietiekamība ir dzīvesveida neatņemama
            daļa.
          </p>
        </div>
      </div>
    </>
  );
};

export default CommunityValuesSelf;
