import React, { useState, useEffect, useReducer } from "react";
import "./RealEstateLand.css";
import RealEstateLandItem from "../realEstateLandItem/RealEstateLandItem";
import RealEstateLandItemModal from "../realEstateLandItemModal/RealEstateLandItemModal";
import axios from "axios";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "../../../node_modules/swiper/swiper.css";

// import required modules
import { Pagination } from "swiper/modules";
import RealEstateLandItemMobile from "../realEstateLandItemMobile/RealEstateLandItemMobile";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const RealEstateLand = () => {
  const [realEstates, dispatch] = useReducer(reducer, initialState);
  const [realEstateLands, setRealEstateLands] = useState([]);
  const [selectedRealEstate, setSelectedRealEstate] = useState(null); // New state for modal specific data
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = (realEstate) => {
    setSelectedRealEstate(realEstate);
    setIsOpenModal(!isOpenModal);
  };
  const closeModal = (realEstate) => {
    setSelectedRealEstate(realEstate);
    setIsOpenModal(isOpenModal);
  };

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `https://www.doles-sala.lv/realEstate/get-all-realEstates`
        );
        dispatch({ type: "SUCCESS", payload: response.data });
        const filteredRealEstateLands = response.data.filter(
          (realEstate) => realEstate.type === "zeme"
        );
        setRealEstateLands(filteredRealEstateLands);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="RealEstateLand">
      <div className="RealEstateLandContentWrapper">
        <div className="RealEstateLandContentAddsWrapper">
          <div className="RealEstateLandContentAddsIntro">
            <h2>Zeme privātmājas būvniecībai</h2>
            <p>
              Svētki un darbi var nākt un iet, bet jūsu mājai ir jābūt
              pastāvīgai. Ja jūs sapņojat par savu ideālo privātmāju un esat
              gatavi sākt būvniecību no nulles, meklējiet īpašumu šajā sadaļā!
            </p>
          </div>
          <div className="RealEstateLandContentAddsItems">
            <Swiper
              slidesPerView={3}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {realEstateLands.map((realEstate) => (
                <SwiperSlide
                  key={realEstate.id}
                  onClick={() => openModal(realEstate)}
                >
                  <RealEstateLandItem RealEstateLand={realEstate} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        {realEstateLands.map((realEstate) => (
          <div key={realEstate.id}>
            <RealEstateLandItemMobile
              RealEstateLand={realEstate}
            />
          </div>
        ))}
        {selectedRealEstate && ( // Show the modal when selectedRealEstate is not null
          <RealEstateLandItemModal
            onClose={closeModal}
            realEstateLands={selectedRealEstate}
            isOpen={openModal} // Pass the modal open state
          />
        )}
      </div>
    </div>
  );
};

export default RealEstateLand;
