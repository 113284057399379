import React from "react";
import "./HomepageEventSectionEventItem.css";
import ArrowIcon from '../../assets/ArrowIcon.svg';

const HomepageEventSectionEventItem = ({ homepageEventSectionEventList }) => {
  const monthAbbreviations = {
    January: "JAN",
    February: "FEB",
    March: "MAR",
    April: "APR",
    May: "MAI",
    June: "JŪN",
    July: "JŪL",
    August: "AUG",
    September: "SEP",
    October: "OKT",
    November: "NOV",
    December: "DEC",
  };

  const dateStart = new Date(homepageEventSectionEventList.dateStart);
  const month = dateStart.toLocaleString("default", { month: "long" });
  const latvianAbbreviation = monthAbbreviations[month] || "???";
  const date = dateStart.toLocaleString("default", { day: "numeric" });

  const openEventLink = () => {
    if (homepageEventSectionEventList.linkEvent) {
      window.open(homepageEventSectionEventList.linkEvent, "_blank");
    }
  };

  return (
    <div className="HomepageEventSectionEventItem">
        <img
          src={homepageEventSectionEventList.image}
          alt={homepageEventSectionEventList.title}
        />
      <div className="HomepageEventSectionEventsItemDateContainer">
          <h3>{latvianAbbreviation}</h3>
          <h1>{date}</h1>
        </div>
      <div
        className="HomepageEventSectionEventsItemTitleContainer"
        onClick={openEventLink}
      >
        <h3>{homepageEventSectionEventList.title}</h3>
        <img className="HomepageEventSectionEventsItemTitleContainerArrow" src={ArrowIcon} alt="arrow" />
      </div>
    </div>
  );
};

export default HomepageEventSectionEventItem;
