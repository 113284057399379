import React from "react";
import "./WhattodoPageIntroMobile.css";

const WhattodoPageIntroMobile = () => {
  return (
    <div className="WhattodoPageIntroMobile">
      <div className="WhattodoPageIntroMobileContent">
        <h1>BRĪVDIENAS</h1>
        <p>
          Došanās dabā labvēlīgi ietekmē tavu fizisko un garīgo veselību, tāpēc
          aicinām atklāt Doles salu un izvēlēties mazāk populārus dabas objektus
          un takas.
        </p>
      </div>
      <img
        src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/HomepageHome_Hero.png"
        alt="hiking"
      />
      <div className="WhattodoPageIntroMobile_Hero_Layer"></div>
    </div>
  );
};

export default WhattodoPageIntroMobile;
