import React from "react";
import "./AccommodationPageItem.css";

const AccommodationPageItem = ({
  title,
  description1,
  description2,
  description3,
  image,
  link,
  icon1,
  icon2,
  icon3,
  icon4,
  icon5,
}) => {
  const openEventLink = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <div className="AccommodationPageItemContainer">
      <div className="AccommodationPageItemImageContainer">
        <img src={image} alt={title} />
      </div>
      <div className="AccommodationPageItemContentContainer">
        <div>
          <h2>{title}</h2>
          <div className="AccommodationPageItemIcons">
            <div>
              <img src={icon1} alt="icon" />
            </div>
            <div>
              <img src={icon2} alt="icon" />
            </div>
            <div>
              <img src={icon3} alt="icon" />
            </div>
            <div>
              <img src={icon4} alt="icon" />
            </div>
            <div>
              <img src={icon5} alt="icon" />
            </div>
          </div>
          <p>{description1}</p>
          <p>{description2}</p>
          <p>{description3}</p>
        </div>
        <div className="AccommodationPageItemContentContainerButton">
          <button onClick={openEventLink}>REZERVĒT</button>
        </div>
      </div>
    </div>
  );
};

export default AccommodationPageItem;
