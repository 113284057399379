import React, {useReducer, useState, useEffect} from 'react'
import './HomepageEventSectionEventList.css'
import HomepageEventSectionEventItem from '../homepageEventSectionEventItem/HomepageEventSectionEventItem'
import axios from 'axios';

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const getRandomEvents = (events, count) => {
  const randomEvents = [...events].sort(() => 0.5 - Math.random());
  return randomEvents.slice(0, count);
};

const HomepageEventSectionEventList = () => {
  const [events, dispatch] = useReducer(reducer, initialState);
  const [upcommingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(`https://www.doles-sala.lv/event/get-all-events`);
        dispatch({ type: "SUCCESS", payload: response.data });

        const randomUpcomingEvents = getRandomEvents(response.data, 3);
        setUpcomingEvents(randomUpcomingEvents);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="HomepageEventSectionEventList">
       {upcommingEvents.map((event) => (
        <HomepageEventSectionEventItem key={event.id} homepageEventSectionEventList={event} />
      ))}
    </div>
  )
}

export default HomepageEventSectionEventList

{/*const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const HomepageEventSectionEventList = () => {
  const [events, dispatch] = useReducer(reducer, initialState);
  const [upcommingEvents, setUpcommingEvents] = useState([]);
  const [visibleEvents, setVisibleEvents] = useState(3);

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `http://localhost:3033/event/get-all-events`
        );
        dispatch({ type: "SUCCESS", payload: response.data });

        const filteredEvents = response.data
        .sort((a, b) => new Date(a.dateStart) - new Date(b.dateStart))
        .slice(0, visibleEvents);

        setUpcommingEvents(filteredEvents);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, [visibleEvents]);

  return (
    <div className="HomepageEventSectionEventList">
       {upcommingEvents.map((event) => (
        <HomepageEventSectionEventItem key={event.id} homepageEventSectionEventList={event} />
      ))}
    </div>
  )
}*/}