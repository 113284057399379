import React, { useState } from 'react';
import './BuildingRulesProhibited.css';
import Arrow from '../../assets/ArrowIcon.svg';

const BuildingRulesProhibited = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [rotate, setRotate] = useState(0);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
    setRotate(rotate === 0 ? 90 : 0);
  };

  const arrowStyle = {
    transform: `rotate(${rotate}deg)`,
    transition: "transform 1s ease",
  };

  return (
    <>
      <div
        className="BuildingRulesSectionHeading__Prohibited"
        onClick={toggleContentVisibility}
      >
        <h2>Kas ir aizliegts visās funkcionālajās zonās?</h2>
        <img src={Arrow} alt="arrow" style={arrowStyle} />
      </div>
      <div
        className={`BuildingRulesSectionParagraphWrapper__Prohibited ${
          isContentVisible ? "visible" : ""
        }`}
      >
        <div className="BuildingRulesSectionParagraph">
          <p>Palīgēkas aizliegts būvēt priekšpagalmā un ārējā sānpagalmā</p>
          <p>
            Ēkās aizliegts iebūvēt lietas, piemēram, ceļojuma treilerus,
            vagoniņus un citas lietas, kas nav būvizstrādājumi
          </p>
          <p>
            Sānu pagalma un aizmugures pagalma platums nedrīkst būt mazāks kā 3
            metri
          </p>
          <p>
            Bez kaimiņu atļaujas būvēt ēku tuvāk par 4 metriem no kaimiņu zemes
            vienības robežas, ja fasādē uz sānu pagalmu vai aizmugures pagalmu
            paredzēti logi
          </p>
        </div>
      </div>
    </>
  );
};

export default BuildingRulesProhibited;
