import React from "react";
import "./CommunityValuesNeighbor.css";

const CommunityValuesNeighbor = () => {
  return (
    <div className="CommunityValuesNeighbor">
      <div className="CommunityValuesNeighborImage">
        <img
          src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/values_kaimins.png"
          alt="Woman on watch"
        />
      </div>
      <div>
        <div className="CommunityValuesNeighborHeading">
          <h2>KAIMIŅŠ KAIMIŅU SARGĀ</h2>
        </div>
        <p>
          Doles sala, kā skaista un iedvesmojoša vieta, ir mājvieta dažādiem
          cilvēkiem, kurus vieno kopīga vērtība - kaimiņš kaimiņu sargā. Šī
          filozofija ir dziļi iesakņojusies Doles salas iedzīvotāju sirdīs,
          veidojot vienotu un saderīgu sabiedrību. Doles sala ir vieta, kurā
          katrs iedzīvotājs ne tikai pazīst savus kaimiņus, bet arī rūpējas un
          atbalsta.
        </p>
      </div>
      <div>
        <p>
          Kaimiņš kaimiņu sargā - tas nozīmē, ka Doles salas iedzīvotāji vienmēr
          ir gatavi sniegt roku palīdzībai, būt klāt, kad tas ir nepieciešams,
          un kopīgi strādāt pie sabiedriskām lietām, lai uzlabotu kopienas
          labklājību.
        </p>
      </div>
      <div>
        <p>
          Šī vērtība veicina ciešas attiecības un solidaritāti starp Doles salas
          iedzīvotājiem. Šī vērtība arī iedrošina rīkot dažādas kopienas
          aktivitātes, festivālus un pasākumus, lai kopā baudītu dzīvi un
          veidotu atmiņas, kas paliek uz mūžu.
        </p>
      </div>
      <div>
        <p>
          Doles sala ir vieta, kurā tiek piemērotas senatnīgas vērtības
          modernajā pasaulē, kur solidaritāte, kopienas sadarbība un atbalsts ir
          stiprākas nekā jebkad agrāk.
        </p>
      </div>
    </div>
  );
};

export default CommunityValuesNeighbor;
