import React from "react";
import "./BuildingRulesMap.css";
import MapZones from "../../assets/MapZones.svg";

const BuildingRulesMap = () => {
  return (
    <div className="BuildingRulesMap">
      <div className="BuildingRulesSectionHeading">
        <h2>Doles salas funkcionālais zonējums</h2>
      </div>
      <div className="BuildingRulesSectionMap">
        <img src={MapZones} alt="Map" />
      </div>
    </div>
  );
};

export default BuildingRulesMap;
