import React from "react";
import "./HomepageEventSectionTitle.css";

const HomepageEventSectionTitle = () => {
  return (
    <div className="HomepageEventSectionTitle">
      <h1>PASĀKUMI</h1>
      <p>
        Vai vēlies apmeklēt mūzikas koncertu, meistarklasi, mākslas izstādi vai
        dabas piedzīvojumu? Dodies uz Doles salu, kura piedāvā to visu un vēl
        daudz ko citu!
      </p>
    </div>
  );
};

export default HomepageEventSectionTitle;
