import React, { useReducer, useEffect, useState } from "react";
import axios from "axios";
import ArrowIcon from "../../assets/ArrowIcon.png";
import './WhattodoPageActivitiesMobile.css'

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const WhattodoPageActivitiesMobile = () => {
  const [whattodoItems, dispatch] = useReducer(reducer, initialState);
  const [whattodoItemsActivities, setWhattodoItemsActivities] = useState([]);

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `https://www.doles-sala.lv/object/get-all-objects`
        );
        dispatch({ type: "SUCCESS", payload: response.data });
        const filteredWhattodoItems = response.data.filter(
          (whattodoItems) => whattodoItems.type === "aktivitates"
        );
        setWhattodoItemsActivities(filteredWhattodoItems);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="WhattodoPageActivitiesMobileWrapper">
    {whattodoItemsActivities.map((whattodoItems) => (
      <div className="WhattodoPageActivitiesMobile">
        <img src={whattodoItems.image} alt={whattodoItems.title} />
        <h2>{whattodoItems.title}</h2>
        <div className="WhattodoPageActivitiesMobileContentTextParagraph">
          <p>{whattodoItems.description[0]}</p>
          <p>{whattodoItems.description[1]}</p>
          <p>{whattodoItems.description[2]}</p>
        </div>
        <a href={whattodoItems.link} target="_blank" rel="noreferrer">
          <div className="WhattodoPageActivitiesMobileContentLink">
            <img src={ArrowIcon} alt="arrow" />
          </div>
        </a>
      </div>
    ))}
  </div>
  )
}

export default WhattodoPageActivitiesMobile