import React from 'react';
import './EventsPage.css';
import Header from '../../components/header/Header';
import EventsIntro from '../../components/eventsIntro/EventsIntro';
import EventsBridge from '../../components/eventsBridge/EventsBridge';
import EventsList from '../../components/eventsList/EventsList';

const EventsPage = () => {
  return (
    <div>
    <Header />
    <EventsIntro />
    <EventsBridge />
    <EventsList />
  </div>
  )
}

export default EventsPage