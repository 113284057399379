import React from 'react';
import './LoginPage.css';
import Header from '../../components/header/Header.jsx';
import HeroImage from '../../assets/HomepageHome_Hero.png';
import Login from '../../components/login/Login';

const LoginPage = () => {
  return (
    <>
      <Header />
      <img alt="hero" className="Loginpage_Hero" src={HeroImage} />
      <div className="Loginpage_Hero_Layer"></div>
      <div className="Login">
        <Login />
      </div>
    </>
  );
};

export default LoginPage;
