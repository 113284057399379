import React from "react";
import "./RealEstateHouseItem.css";

const RealEstateHouseItem = ({ realEstateHouse }) => {
  return (
    <>
      <div className="RealEstateHouseItem">
        <h2>{realEstateHouse.address}</h2>
        <h3 className="RealEstateHouseItemTextStyle">{realEstateHouse.title}</h3>
        <div className="RealEstateHouseItemImage">
          <img src={realEstateHouse.image} alt="house"/>
        </div>
        <div className="RealEstateHouseItemArea">
          <h3 className="RealEstateHouseItemAreaTextStyle">
            {realEstateHouse.areaHouse} m<sup>2</sup>
          </h3>
        </div>
        <div className="RealEstateHouseItemPrice">
          <h2>{realEstateHouse.price} €</h2>
        </div>
      </div>
    </>
  );
};

export default RealEstateHouseItem;
