import React, { forwardRef } from "react";
import "./AccommodationPageList.css";
import AccommodationPageItem from "../accommodationPageItem/AccommodationPageItem";
import AccommodationPageItemMobile from "../accommodationPageItemMobile/AccommodationPageItemMobile";
import Sauna from "../../assets/Sauna.svg";
import HotTube from "../../assets/Jacuzzi.svg";
import Kitchen from "../../assets/Kitchen.svg";
import BBQ from "../../assets/Grill.svg";
import Beach from "../../assets/Beach.svg";
import SUP from "../../assets/PaddleBoard.svg";
import Boat from "../../assets/Boat.svg";
import Patio from "../../assets/Patio.svg";
import Fire from "../../assets/Fire.svg";

const AccommodationPageList = forwardRef((props, ref) => {
  return (
    <div className="AccommodationPageList" ref={ref}>
      <section id="SS">
        <AccommodationPageItem
          title={"Pirts māja SAULES STARI"}
          image={
            "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/SaulesStari.png"
          }
          link={
            "https://www.airbnb.com/rooms/21322197?check_in=2023-10-24&check_out=2023-10-25&guests=1&adults=1&s=67&unique_share_id=81a9d10e-393b-471f-ba2c-fdcdad88e0ce"
          }
          description1={
            "Klusa, mūsdienīga vieta atpūtai un nakšņošanai ar skaistu skatu uz Daugavu un Rīgas pilsētas panorāmu. Tiem, kas vēlas baudīt mierīgus vakarus."
          }
          description2={
            "Šī ir vieta, kurā laiks iet savā tempā, un katrs mirklis ir kā dāvana, piedāvājot atjaunoties un atgūt savu iekšējo mieru."
          }
          description3={
            "Neatkarīgi no tā, vai ceļojat ar mīļoto, draugiem vai vienkārši vēlaties būt viens ar savām domām, šī vieta ir ideāla, lai dzīvotu pašreizējā mirklī un sajustu, kā pasaule ap jums palēnina tempu."
          }
          icon1={Sauna}
          icon2={Kitchen}
          icon3={BBQ}
          icon4={Beach}
          icon5={SUP}
        />
      </section>
      <section id="KS">
        <AccommodationPageItem
          title={"Brīvdienu māja KLUSUMA SKAŅAS"}
          image={
            "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/KlusumaSkan%CC%A7as.jpeg"
          }
          link={
            "https://www.airbnb.com/rooms/792432801506911889?check_in=2023-10-16&check_out=2023-10-17&guests=1&adults=1&s=67&unique_share_id=d26b3b90-83a1-459b-b428-2409c609d197"
          }
          description1={
            "Šis mazais viesu nams atrodas klusā, rāmā vidē, dabas ieskautā un unikālā vietā blakus Daugavai."
          }
          description2={
            "Pats viesu nams ir gaumīgi iekārtots un pilnībā aprīkots ar visu nepieciešamo komfortablai atpūtai. Jūs varat pasūtīt karsto kublu romantiskai atpūtai vai ģimenes izklaidei."
          }
          description3={
            "Tikai 20 minūšu brauciena attālumā no trokšņainās pilsētas, un jūs jau ieskauj klusuma skaņas."
          }
          icon1={HotTube}
          icon2={Kitchen}
          icon3={BBQ}
          icon4={Beach}
          icon5={SUP}
        />
      </section>
      <section id="KD">
        <AccommodationPageItem
          title={"Namiņš KEMPINGS DOLE"}
          image={
            "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/KempingsDole.png"
          }
          link={
            "https://www.airbnb.com/rooms/49069138?check_in=2024-05-03&check_out=2024-05-05&guests=1&adults=1&s=67&unique_share_id=a9e0bf75-ef61-44eb-9d70-a6d26085de94"
          }
          description1={
            "Dažkārt pārmaiņu viļņos atrodam savus patiesos virzienus – Kempings Dole."
          }
          description2={
            "Ļoti romantiska vieta pāriem dabas baudīšanai, BBQ, braukšanai ar SUP dēļiem vai airu laivām. Labākie saulrieta skati pār Daugavu tiks piedzīvoti tieši šeit."
          }
          description3={
            "Maza koka mājiņa dažu soļu attālumā no Daugavas Doles salā, tikai 20 minūšu brauciena attālumā no Latvijas galvaspilsētas."
          }
          icon1={Boat}
          icon2={Patio}
          icon3={BBQ}
          icon4={Beach}
          icon5={Fire}
        />
      </section>
      <section id="DS">
        <AccommodationPageItem
          title={"Brīvdienu māja DOLES SKATI"}
          image={
            "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/DolesSkati.jpeg"
          }
          link={"https://www.booking.com/Share-YIGPAv"}
          description1={
            "Izbaudiet brīnišķīgu laiku un piedzīvojiet, kā mostas daba, neatkarīgi no tā, vai plānojat romantisku atpūtu vai satikšanos draugu lokā."
          }
          description2={
            "DOLES SKATI piedāvā romantisku atpūtu Daugavas krastā ar lielisku skatu uz Rīgas panorāmu. Šeit ir pieejami trīs divvietīgi koka namiņi."
          }
          description3={
            "Iekārtojoties šajā mājīgajā vietā, jūs varēsiet izbaudīt aizmirstas dziesmas no pagātnes, kamēr Daugavas ūdeņi klusējot vijas aiz loga."
          }
          icon1={Sauna}
          icon2={HotTube}
          icon3={BBQ}
          icon4={Beach}
          icon5={SUP}
        />
      </section>
      <section id="DP">
        <AccommodationPageItem
          title={"Eco un spa atpūta DOLES PĒRLE"}
          image={
            "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/DolesPe%CC%84rle.jpeg"
          }
          link={"https://www.instagram.com/dolesperle/"}
          description1={
            "Eco un spa atpūta pašā Daugavas krastā, kas ļauj baudīt ainavu un klusumu, dzirdot tikai ūdens plūdumu."
          }
          description2={
            "Šī vieta ir ideāla izvēle, ja vēlaties vienlaicīgi iepriecināt savu ķermeni un dvēseli, baudot ekoloģiski tīru un harmonisku vidi."
          }
          description3={
            "Šeit ir pieejamas trīs mājiņas, kur katrā no tām varat atpūsties karstajā kublā vai izbaudīt saunu."
          }
          icon1={Sauna}
          icon2={HotTube}
          icon3={BBQ}
          icon4={Beach}
          icon5={SUP}
        />
      </section>
      <AccommodationPageItemMobile
        heading={"Pirts māja"}
        title={"SAULES STARI"}
        image={
          "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/SaulesStari.png"
        }
        link={
          "https://www.airbnb.com/rooms/21322197?check_in=2023-10-24&check_out=2023-10-25&guests=1&adults=1&s=67&unique_share_id=81a9d10e-393b-471f-ba2c-fdcdad88e0ce"
        }
      />
      <AccommodationPageItemMobile
        heading={"Brīvdienu māja"}
        title={"KLUSUMA SKAŅAS"}
        image={
          "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/KlusumaSkan%CC%A7as.jpeg"
        }
        link={
          "https://www.airbnb.com/rooms/792432801506911889?check_in=2023-10-16&check_out=2023-10-17&guests=1&adults=1&s=67&unique_share_id=d26b3b90-83a1-459b-b428-2409c609d197"
        }
      />
      <AccommodationPageItemMobile
        heading={"Namiņš"}
        title={"KEMPINGS DOLE"}
        image={
          "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/KempingsDole.png"
        }
        link={
          "https://www.airbnb.com/rooms/49069138?check_in=2024-05-03&check_out=2024-05-05&guests=1&adults=1&s=67&unique_share_id=a9e0bf75-ef61-44eb-9d70-a6d26085de94"
        }
      />
      <AccommodationPageItemMobile
        heading={"Brīvdienu māja"}
        title={"DOLES SKATI"}
        image={
          "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/DolesSkati.jpeg"
        }
        link={"https://www.booking.com/Share-YIGPAv"}
      />
      <AccommodationPageItemMobile
        heading={"Eco un spa atpūta"}
        title={"DOLES PĒRLE"}
        image={
          "https://s3.eu-north-1.amazonaws.com/doles-sala.lv/DolesPe%CC%84rle.jpeg"
        }
        link={"https://www.instagram.com/dolesperle/"}
      />
    </div>
  );
});

export default AccommodationPageList;
