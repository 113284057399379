import React, { useState } from 'react';
import './BuildingRulesForest.css';
import Hotel from '../../assets/Hotel.svg';
import Tourism from '../../assets/Tourism.svg';
import Farm from '../../assets/Farm.svg';
import Tower from '../../assets/Tower.svg';
import Arrow from '../../assets/ArrowIcon.svg';

const BuildingRulesForest = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [rotate, setRotate] = useState(0);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
    setRotate(rotate === 0 ? 90 : 0);
  };

  const arrowStyle = {
    transform: `rotate(${rotate}deg)`,
    transition: "transform 1s ease",
  };

  return (
    <div>
      <div
        className="BuildingRulesSectionHeading__Forest"
        onClick={toggleContentVisibility}
      >
        <h2>Meža teritoriju apbūves noteikumi</h2>
        <img src={Arrow} alt="arrow" style={arrowStyle} />
      </div>
      <div
        className={`BuildingRulesSectionParagraphWrapper__Forest ${
          isContentVisible ? "visible" : ""
        }`}
      >
        <p className="BuildingRulesSectionParagraphBold">
          Mežu teritorijā atļauta šādu ēku būvniecība:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Tower} alt="Outdoor table icon" />
            <p>Ar mežsaimniecību saistītas ēkas un inženierbūves</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Farm} alt="Outdoor table icon" />
            <p>
              Ar medniecību, t.sk. arī ar savvaļas sugu dzīvnieku turēšanu
              saistītas inženierbūves
            </p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Tourism} alt="Outdoor table icon" />
            <p>Ar tūrismu saistītas inženierbūves</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Hotel} alt="Hotel icon" />
            <p>
              Vienas viesnīcas, restorāna, kafejnīcas un bāra ēkas būvniecība,
              kuras kopējā platība nepārsniedz 400 m² un ne vairāk kā diviem
              virszemes stāviem ar jumta izbūvi
            </p>
          </div>
        </div>
        <p className="BuildingRulesSectionParagraphBold">
          Meža zemju teritorijā ievēro šādas apbūves prasības:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <p>No jauna veidojamās zemes vienības platība ir ne mazāka kā 4 ha</p>
          <p>
            Mežu teritorijās zemes vienību aizliegts norobežot ar žogu, izņemot
            zemes vienību vai tās daļu, kurās paredzēta savvaļas sugu dzīvnieku
            turēšana
          </p>
        </div>
      </div>
    </div>
  );
};

export default BuildingRulesForest;
