import React from "react";
import "./HomepageBlogTowBar.css";
import ArrowIconEvents from "../../assets/ArrowIcon.svg";
import { Link } from "react-router-dom";

const HomepageBlogTowBar = () => {
  return (
    <div className="HomepageBlogTowBar">
      <div className="HomepageBlogTowBarTextContainer">
        <div className="HomepageBlogTowBarTextWrapper">
          <div>
            <h2>KAS IR TAUVAS JOSLA?</h2>
            <p>
              Kas īsti ir tauvas josla, kura apjož Daugavu? Ko tajā ir atļauts un
              ko aizliegts darīt apmeklētājiem? Un kādas tiesības un kādi
              pienākumi ir zemes īpašniekiem?
            </p>
            <p>Par to visu tu varēsi uzzināt, izlasot šo rakstu.</p>
          </div>
          <div className="HomepageBlogTowBarTextContainerLink">
            <Link className="HomepageBlogTowBarLink" to="/blogs/tauvas-josla">
              <p>Visu rakstu lasi šeit</p>
              <img
                className="HomepageBlogTowBarTextContainerLinkArrow"
                src={ArrowIconEvents}
                alt="arrow"
              />
            </Link>
          </div>
        </div>
      </div>
      <img
        className="HomepageBlogTowBarImageFisher"
        src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/Fisher.jpg"
        alt="Fisher"
      />
    </div>
  );
};

export default HomepageBlogTowBar;
