import React from "react";
import "./RealEstateLandItem.css";

const RealEstateLandItem = ({ RealEstateLand }) => {
  return (
    <>
      <div className="RealEstateLandItem">     
        <h2>{RealEstateLand.address}</h2>
        <h3 className="RealEstateLandItemTextStyle">{RealEstateLand.title}</h3>
        <div className="RealEstateLandItemArea">
          <h3 className="RealEstateLandItemAreaTextStyle">
            {RealEstateLand.areaLand} m<sup>2</sup>
          </h3>
        </div>
        <div className="RealEstateLandItemPrice">
          <h2>{RealEstateLand.price} €</h2>
        </div>
        <img src={RealEstateLand.image} alt="location of real estate" />
      </div>
    </>
  );
};

export default RealEstateLandItem;
