import React from "react";
import { Link } from "react-router-dom"
import "./AccommodationPageMap.css";
import Map from "../../assets/MapAccommodation-03.svg";
import SS from "../../assets/SS.png";
import KS from "../../assets/KS.png";
import KD from "../../assets/KD.png";
import DS from "../../assets/DS.png";
import DP from "../../assets/DP.png";

const AccommodationPageMap = ({ scrollToSection }) => {
  return (
    <div className="AccommodationPageMap">
      <div className="AccommodationPageMapContainer">
        <div className="AccommodationPageMapContainerContent">
          <div>
          <h1 className="AccommodationPageMapHeading">
            KUR NAKŠŅOT DOLES SALĀ?
          </h1>
          <p>
            Doles sala ir burvīgs un pievilcīgs ceļojuma galamērķis daudzu
            iemeslu dēļ. Šī sala piedāvā neaizmirstamu dabas skaistumu,
            izklaides un dabas spēku, kā arī iespēju iepazīt vēsturi un kultūru.
          </p>
          <p>
            Izcila vieta, lai relaksētos un izbaudītu klusumu, kā arī aktīvās
            atpūtas iespējas, piemēram, pastaigas, riteņbraukšanu un ūdens
            sportu.
          </p>
          <p> Neaizmirstams piedzīvojums jūs gaida Doles salā!</p>
          </div>
          <div className="AccommodationPageMapButtons">
            <Link className="AccommodationPageEventButton" to="/pasakumi">
              <input type="button" value="PASĀKUMI"></input>
            </Link>
            <Link className="AccommodationPageEventButton" to="/ko-darit">
              <input type="button" value="KO DARĪT?"></input>
            </Link>
          </div>
        </div>
        <div className="AccommodationPageMapContent">
          <img src={Map} alt="Map" />
          <div
            className="AccommodationPageMapSS"
            onClick={() => scrollToSection("SS")}
          >
            <img src={SS} alt="Saules Stari" />
          </div>
          <div
            className="AccommodationPageMapKS"
            onClick={() => scrollToSection("KS")}
          >
            <img src={KS} alt="Klusuma Skaņas" />
          </div>
          <div
            className="AccommodationPageMapKD"
            onClick={() => scrollToSection("KD")}
          >
            <img src={KD} alt="Kempings Dole" />
          </div>
          <div
            className="AccommodationPageMapDS"
            onClick={() => scrollToSection("DS")}
          >
            <img src={DS} alt="Doles Skati" />
          </div>
          <div
            className="AccommodationPageMapDP"
            onClick={() => scrollToSection("DP")}
          >
            <img src={DP} alt="Doles Skati" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccommodationPageMap;
