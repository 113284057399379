import React from "react";
import "./RealEstatePage.css";
import Header from "../../components/header/Header";
import RealEstateIntro from "../../components/realEstateIntro/RealEstateIntro";
import RealEstateLand from "../../components/realEstateLand/RealEstateLand";
import RealEstateHouse from "../../components/realEstateHouse/RealEstateHouse";
import RealEstateInvest from "../../components/realEstateInvest/RealEstateInvest";

const RealEstatePage = () => {
  return (
    <>
      <Header />
      <RealEstateIntro />
      <RealEstateLand />
      <RealEstateHouse />
      <RealEstateInvest />
    </>
  );
};

export default RealEstatePage;
