import React from 'react';
import './EventsItem.css';
import ArrowIconEvents from '../../assets/ArrowIconEvents.svg';

const EventsItem = ({ eventList }) => {
  const monthAbbreviations = {
    January: "JAN",
    February: "FEB",
    March: "MAR",
    April: "APR",
    May: "MAI",
    June: "JŪN",
    July: "JŪL",
    August: "AUG",
    September: "SEP",
    October: "OKT",
    November: "NOV",
    December: "DEC",
  };

  const dateStart = new Date(eventList.dateStart);
  const month = dateStart.toLocaleString("default", { month: "long" });
  const latvianAbbreviation = monthAbbreviations[month] || "???";
  const date = dateStart.toLocaleString("default", { day: "numeric" });
  const timeStart = new Date(eventList.timeStart);
  const hours = timeStart.getUTCHours();
  const minutes = timeStart.getUTCMinutes();
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;

  const openEventLink = () => {
    if (eventList.linkEvent) {
      window.open(eventList.linkEvent, "_blank");
    }
  };

  return (
    <div className="EventsItemContainer">
      <div className="EventsItemDateContainer">
        <h3>{latvianAbbreviation}</h3>
        <h1>{date}</h1>
      </div>
      <div className="EventsItemImageContainer">
        <img src={eventList.image} alt={eventList.title} />
      </div>
      <div className="EventsItemContentContainer">
        <div>
          <h2>{eventList.title}</h2>
          <p className="EventsItemContentAddress">{eventList.address}</p>
          <p className="EventsItemContentStartTime">Sākums: {formattedTime}</p>
          <p>{eventList.description}</p>
        </div>
        <div className="EventsItemContentContainerButton">
          <button onClick={openEventLink}>
            Vairāk par pasākumu uzzini šeit{" "}
            <img src={ArrowIconEvents} alt="arrow" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventsItem;
