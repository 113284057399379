import React from 'react';
import './BuildingPage.css';
import Header from '../../components/header/Header';
import BuildingRules from '../../components/buildingRules/BuildingRules';

const BuildingPage = () => {
  return (
    <div>
    <Header />
    <div className='BuildingPage'>
      <BuildingRules />
    </div>
  </div>
  )
}

export default BuildingPage