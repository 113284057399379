import React from 'react';
import './HomepageHeroSectionContent.css';
import LocationIcon from '../../assets/Location_icon.svg';

const HomepageHeroSectionContent = () => {
  return (
    <>
      <div className="HomepageHomeContainer">
        <div className="HomepageHomeContainerUpper">
          <div className="HomepageHomeContainerUpperLeft">
            <div className="HomepageHomeContainerUpperLeftItem"></div>
            <div className="HomepageHomeContainerUpperLeftItem"></div>
            <div className="HomepageHomeContainerUpperLeftItem"></div>
            <div className="HomepageHomeContainerUpperLeftItem"></div>
          </div>
          <div className="HomepageHomeContainerUpperRight">
            <h1>DOLES SALA</h1>
            <h2>Iebrist mīkstā sūnā</h2>
            <h2>Un dzert sulu tieši no koka</h2>
            <h2>Klusums dzird manas sirds pulksteni</h2>
            <h2>Un skaņa vēja mīkstumā sāp</h2>
            <h2>Stipras lai visas tās takas pa kurām atnākts</h2>
            <h2>Un visas tās citas pa kurām vēl iešu</h2>
          </div>
        </div>
        <div className="HomepageHomeContainerLower">
          <div className="HomepageHomeContainerLowerLeft">
            <img alt="location" className="LocationIcon" src={LocationIcon} />
          </div>
          <div className="HomepageHomeContainerLowerRight">
            <h3>DOLES SALAS DABAS PARKS</h3>
            <p>SALASPILS LAUKU TERITORIJA, SALASPILS NOVADS</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomepageHeroSectionContent;
