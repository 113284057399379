import React, { useState, useReducer, useEffect } from "react";
import "./RealEstateHouse.css";
import axios from "axios";
import RealEstateHouseItem from "../realEstateHouseItem/RealEstateHouseItem";
import RealEstateHouseItemModal from "../realEstateHouseItemModal/RealEstateHouseItemModal";
import RealEstateHouseItemMobile from "../realEstateHouseItemMobile/RealEstateHouseItemMobile";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "../../../node_modules/swiper/swiper.css";

// import required modules
import { Pagination } from "swiper/modules";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const RealEstateHouse = () => {
  const [realEstates, dispatch] = useReducer(reducer, initialState);
  const [realEstateHouses, setRealEstateHouses] = useState([]);
  const [selectedRealEstate, setSelectedRealEstate] = useState(null); // New state for modal specific data
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = (realEstate) => {
    setSelectedRealEstate(realEstate);
    setIsOpenModal(true);
  };
  const closeModal = (realEstate) => {
    setSelectedRealEstate(realEstate);
    setIsOpenModal(false);
  };

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `https://www.doles-sala.lv/realEstate/get-all-realEstates`
        );
        dispatch({ type: "SUCCESS", payload: response.data });
        const filteredRealEstateHouses = response.data.filter(
          (realEstate) => realEstate.type === "māja"
        );
        setRealEstateHouses(filteredRealEstateHouses);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="RealEstateHouse">
      <div className="RealEstateHouseContentWrapper">
        <div className="RealEstateHouseContentAddsWrapper">
          <div className="RealEstateHouseContentAddsIntro">
            <h2>Māju pārdošanu sludinājumi</h2>
            <p>
              Sapņojat par dzīvi pie upes? Mēs varam padarīt jūsu sapni par
              realitāti! Dole sala ir vieta, kur dabas skaistums saplūst ar
              mākslas un kultūras bagātībām. Ja esat gatavs īstenot savu sapni
              par māju pie upes, tad Dole sala ir vieta, kur jūs varat to sākt.
            </p>
            <p>
              Šī sadaļa piedāvā informāciju par māju pārdošanu Doles salā.
              Neatkarīgi no tā, vai esat pircējs, kurš meklē savu sapņu māju,
              vai pārdevējs, kurš vēlas atrast piemērotu pircēju, mēs esam šeit,
              lai jums palīdzētu šajā procesā.
            </p>
          </div>
          <div>
            <Swiper
              slidesPerView={4}
              spaceBetween={0}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {realEstateHouses.map((realEstate) => (
                <SwiperSlide
                  key={realEstate.id}
                  onClick={() => openModal(realEstate)}
                >
                  <RealEstateHouseItem realEstateHouse={realEstate} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        {realEstateHouses.map((realEstate) => (
          <div key={realEstate.id}>
            <RealEstateHouseItemMobile realEstateHouse={realEstate} />
          </div>
        ))}
        {selectedRealEstate && ( // Show the modal when selectedRealEstate is not null
          <RealEstateHouseItemModal
            onClose={closeModal}
            realEstateHouses={selectedRealEstate}
            isOpen={isOpenModal} // Pass the modal open state
          />
        )}
      </div>
    </div>
  );
};

export default RealEstateHouse;
