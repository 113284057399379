import React from 'react'
import './RealEstateHouseItemMobile.css'

const RealEstateHouseItemMobile = ({ realEstateHouse }) => {
  return (
    <>
    <div className="RealEstateHouseItemMobile">
      <h2>{realEstateHouse.address}</h2>
      <h3 className="RealEstateHouseItemMobileTextStyle">{realEstateHouse.title}</h3>
      <div className="RealEstateHouseItemMobileImage">
        <img src={realEstateHouse.image} alt="house"/>
      </div>
      <div className="RealEstateHouseItemMobileArea">
        <h3 className="RealEstateHouseItemMobileAreaTextStyle">
          {realEstateHouse.areaHouse} m<sup>2</sup>
        </h3>
      </div>
      <div className="RealEstateHouseItemMobilePrice">
        <h2>{realEstateHouse.price} €</h2>
      </div>
    </div>
  </>
  )
}

export default RealEstateHouseItemMobile