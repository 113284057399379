import React from 'react'
import './RealEstateLandItemMobile.css'

const RealEstateLandItemMobile = ({ RealEstateLand }) => {
  return (
    <>
    <div className="RealEstateLandItemMobile">     
      <h2>{RealEstateLand.address}</h2>
      <h3 className="RealEstateLandItemMobileTextStyle">{RealEstateLand.title}</h3>
      <div className="RealEstateLandItemMobileArea">
        <h3 className="RealEstateLandItemMobileAreaTextStyle">
          {RealEstateLand.areaLand} m<sup>2</sup>
        </h3>
      </div>
      <div className="RealEstateLandItemMobilePrice">
        <h2>{RealEstateLand.price} €</h2>
      </div>
      <img src={RealEstateLand.image} alt="location of real estate" />
    </div>
  </>
  )
}

export default RealEstateLandItemMobile