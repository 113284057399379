import React, {useState} from 'react';
import './BuildingRulesFactory.css';
import CarPark from '../../assets/CarPark.svg';
import CarWash from '../../assets/CarWash.svg';
import Factory from '../../assets/Factory.svg';
import Warehouse from '../../assets/Warehouse.svg';
import Silos from '../../assets/Silos.svg';
import Arrow from '../../assets/ArrowIcon.svg';

const BuildingRulesFactory = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [rotate, setRotate] = useState(0);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
    setRotate(rotate === 0 ? 90 : 0);
  };

  const arrowStyle = {
    transform: `rotate(${rotate}deg)`,
    transition: "transform 1s ease",
  };

  return (
    <>
        <div className="BuildingRulesSectionHeading__Factory"
        onClick={toggleContentVisibility}>
          <h2>Rūpnieciskās apbūves teritoriju apbūves noteikumi</h2>
          <img src={Arrow} alt="arrow" style={arrowStyle} />
        </div>
        <div className={`BuildingRulesSectionParagraphWrapper__Factory ${
          isContentVisible ? "visible" : ""
        }`}>
          <p className="BuildingRulesSectionParagraphBold">
            Rūpnieciskās apbūves teritorijā atļauta šādu ēku būvniecība:
          </p>
          <div className="BuildingRulesSectionParagraph">
            <div className="BuildingRulesSectionParagraphPart">
              <img src={Factory} alt="Car park icon" />
              <p>Viena vai vairākas rūpnieciskās ražošanas ēkas</p>
            </div>
            <div className="BuildingRulesSectionParagraphPart">
              <img src={Silos} alt="Car park icon" />
              <p>
                Viena vai vairākas rezervuāru, bunkuru, silosu un noliktavu ēkas
              </p>
            </div>
            <div className="BuildingRulesSectionParagraphPart">
              <img src={Warehouse} alt="Car park icon" />
              <p>Viena vai vairākas elevatoru - noliktavu uzņēmumu būves</p>
            </div>
            <div className="BuildingRulesSectionParagraphPart">
              <img src={CarPark} alt="Car park icon" />
              <p>Viens vai vairāki automašīnu stāvlaukumi</p>
            </div>
            <div className="BuildingRulesSectionParagraphPart">
              <img src={CarWash} alt="Road icon" />
              <p>
                Viena vai vairākas rūpnieciskās ražošanas ēkas, kas paredzētas
                auto remonta darbnīcām, vai auto mazgātuvei
              </p>
            </div>
          </div>
          <p className="BuildingRulesSectionParagraphBold">
            Rūpnieciskās apbūves teritorijā ievēro šādas apbūves prasības:
          </p>
          <div className="BuildingRulesSectionParagraph">
            <p>
              No jauna veidojamās zemes vienības platība ir ne mazāka par 1500
              m2; zemes vienības minimālā fronte ir 15 metri
            </p>
            <p>Apbūves maksimālais augstums 15 metri</p>
            <p>Maksimālais stāvu skaits ir 4 stāvi</p>
            <p>Zemes vienības maksimālā apbūves intensitāte nepārsniedz 150%</p>
            <p>Brīvā teritorija ir vismaz 10%</p>
            <p>
              Rūpnieciskās apbūves teritorijā zemes vienību atļauts norobežot ar
              blīvu necaurredzamu žogu, kura augstums nepārsniedz 2.2 m
            </p>
          </div>
        </div>
    </>
  )
}

export default BuildingRulesFactory