import React from "react";
import "./RealEstateInvestItemMobile.css"

const RealEstateInvestItemMobile = ({ realEstateInvest }) => {
  return (
    <>
      <div className="RealEstateInvestItemMobile">
        <h2>{realEstateInvest.address}</h2>
        <h3 className="RealEstateInvestItemMobileTextStyle">
          {realEstateInvest.title}
        </h3>
        <div className="RealEstateInvestItemMobileImage">
          <img src={realEstateInvest.image} alt="house" />
        </div>
        <div className="RealEstateInvestItemMobileArea">
          <h3 className="RealEstateInvestItemMobileAreaTextStyle">
            {realEstateInvest.areaHouse} m<sup>2</sup>
          </h3>
        </div>
        <div className="RealEstateInvestItemMobilePrice">
          <h2>{realEstateInvest.price} €</h2>
        </div>
      </div>
    </>
  );
};

export default RealEstateInvestItemMobile;
