import React from "react";
import "./RealEstateLandItemModal.css";
import CloseIcon from "../../assets/CloseIcon.svg";
import PhoneIcon from "../../assets/PhoneIcon.svg";
import MailIcon from "../../assets/MailGrey.svg";

const RealEstateLandItemModal = ({ onClose, realEstateLands, isOpen }) => {
  const handleModalClose = (event) => {
    event.stopPropagation(); // Prevent event propagation
    onClose();
  };

  return (
    <div className={`RealEstateLandItemModal ${isOpen ? "open" : ""}`}>
      <div className="RealEstateLandItemModalWrapper">
        <div className="RealEstateLandItemModalCloseIcon" onClick={handleModalClose}>
          <img src={CloseIcon} alt="close icon" />
        </div>
        <div className="RealEstateLandItemModalContent">
          <h3>Atļautā izmantošana</h3>
          <p>{realEstateLands.purpose[0]}</p>
          <p>{realEstateLands.purpose[1]}</p>
        </div>
        <div>
          <h3>Zemes gabals atrodas pie ūdens</h3>
          <p>{realEstateLands.riverside}</p>
        </div>
        <div>
          <h3>Kontaktinformācija</h3>
          <div className="RealEstateLandItemModalContactPhoneWrapper">
            <div className="RealEstateLandItemModalPhoneIcon">
              <img src={PhoneIcon} alt="phone" />
            </div>
            <p>{realEstateLands.phoneNumber}</p>
          </div>
          <div className="RealEstateLandItemModalContactPhoneWrapper">
            <div className="RealEstateLandItemModalMailIcon">
              <img src={MailIcon} alt="phone" />
            </div>
            <p>{realEstateLands.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateLandItemModal;
