import React from "react";
import "./CommunityValuesSmart.css";

export const CommunityValuesSmart = () => {
  return (
    <div className="CommunityValuesSmart">
      <div className="CommunityValuesSmartImage">
        <img
          src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/values_vieda.png"
          alt="Smart community"
        />
      </div>
      <div>
        <div className="CommunityValuesSmartHeading">
          <h2>VIEDA KOPIENA</h2>
        </div>
        <p>
          Doles sala ir vieta, kurā dzīvojošā kopiena iemieso vērtību, kuru
          varētu nosaukt kā "Vieda kopiena". Šī nelielā sala Daugavas
          sirdī ir kļuvusi par vietu, kurā cilvēki apvieno spēkus, lai radītu
          vienotu, gudru un ilgtspējīgu kopienas dzīvi. Viens no Doles salas
          izcilākajiem aspektiem ir kopienas sapratne par ilgtspējīgu attīstību
          un dabas aizsardzību. Iedzīvotāji ciena un ievēro vietējo vidi,
          nodrošinot, ka tos ieskaujošie resursi tiek lietoti atbildīgi un
          ilgtermiņā. Viņi izmanto mūsdienu tehnoloģijas un inovācijas, lai
          samazinātu negatīvo ietekmi uz dabas vidi un veicinātu ilgtspējīgas
          enerģijas avotu izmantošanu.
        </p>
      </div>
      <div>
        <p>
          Turklāt Doles sala ir vieta, kur cilvēki rūpējas par izglītību un
          zināšanām. Kopiena veicina izglītību visās vecuma grupās, attīsta
          jauniešu prasmes un izpratni par ilgtspējību. Viņi cenšas izglītot
          sevi un citus, lai veicinātu zināšanu un izpratnes augšanu kopienā.
        </p>
      </div>
      <div>
        <p>
          Tomēr vieda kopiena Doles salā nozīmē arī emocionālu un sociālu
          atbalstu. Cilvēki rūpējas viens par otru, veido ciešas attiecības
          un sadarbību, lai risinātu problēmas un izaicinājumus. Šī
          ir viena no galvenajām vērtībām, kas
          padara Doles salu par vietu, kur dzīvot un attīstīties.
        </p>
      </div>
      <div>
        <p>
          Visbeidzot, Doles sala ar savu viedo kopienas pieeju ir piemērs citām
          vietām un sabiedrībām. Tā demonstrē, ka, izmantojot zināšanas,
          inovācijas un kopienas sadarbību, ir iespējams veidot ilgtspējīgu
          nākotni, kur cilvēki, vide un sabiedrība dzīvo harmonijā.
        </p>
      </div>
    </div>
  );
};

export default CommunityValuesSmart;
