import React from 'react';
import './CommunityValuesIntro.css';
import CommunityValuesQuality from '../communityValuesQuality/CommunityValuesQuality';
import CommunityValuesSelf from '../communityValuesSelf/CommunityValuesSelf';
import CommunityValuesSmart from '../communityValuesSmart/CommunityValuesSmart';
import CommunityValuesExampl from '../communityValuesExampl/CommunityValuesExampl';
import CommunityValuesNeighbor from '../communityValuesNeighbor/CommunityValuesNeighbor';
import CommunityValuesStorm from '../communityValuesStorm/CommunityValuesStorm';
import CommunityValuesSafe from '../communityValuesSafe/CommunityValuesSafe';

const communityValuesIntro = () => {
  return (
    <div className="CommunityValuesIntroContainer">
      <h1>KOPIENAS VĒRTĪBAS</h1>
      <CommunityValuesSafe />
      <CommunityValuesQuality />
      <CommunityValuesSelf />
      <CommunityValuesSmart />
      <CommunityValuesExampl />
      <CommunityValuesNeighbor />
      <CommunityValuesStorm />
    </div>
  );
};

export default communityValuesIntro;
