import React, { useState } from "react";
import "./HeaderMobile.css";
import Logo from "../../assets/Dolessala_logo_WEB-02.svg";
import UserIcon from "../../assets/UserIcon.svg";
import { Link } from "react-router-dom";

const HeaderMobile = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!isMenuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <div className="HeaderMobile">
      <div className="HeaderMobileLogo">
        <Link to="/" onClick={closeMenu}>
          <img src={Logo} alt="Dole Icon" />
        </Link>
      </div>
      <div className="HeaderMobileButtons">
        <Link to="/ienakt" onClick={closeMenu}>
          <img src={UserIcon} alt="User Icon" />
        </Link>
        <div
          className={`HeaderMobileHamburgerMenu ${isMenuOpen ? "active" : ""}`}
          onClick={handleMenuToggle}
        >
          <div class="HeaderMobileHamburgerMenuLine"></div>
          <div class="HeaderMobileHamburgerMenuLine"></div>
          <div class="HeaderMobileHamburgerMenuLine"></div>
        </div>
      </div>
      <div class={`HeaderMobileMenu ${isMenuOpen ? "open" : ""}`}>
        <ul>
          {/*<li>
              <Link
                className="HeaderMobileLink"
                to="/par-mums"
                onClick={closeMenu}
              >
                PAR MUMS
              </Link>
          </li>*/}
          <li>
            <Link
              className="HeaderMobileLink"
              to="/kopienas-vertibas"
              onClick={closeMenu}
            >
              KOPIENAS VĒRTIBAS
            </Link>
          </li>
          <li>
            <Link
              className="HeaderMobileLink"
              to="/pasakumi"
              onClick={closeMenu}
            >
              PASĀKUMI
            </Link>
          </li>
          <li>
            <Link
              className="HeaderMobileLink"
              to="/naktsmitnes"
              onClick={closeMenu}
            >
              KUR NAKŠŅOT?
            </Link>
          </li>
          <li>
            <Link
              className="HeaderMobileLink"
              to="/ko-darit"
              onClick={closeMenu}
            >
              KO DARĪT?
            </Link>
          </li>
          <li>
            <Link className="HeaderMobileLink" to="/apbuve" onClick={closeMenu}>
              DZĪVE UZ SALAS
            </Link>
          </li>
          <li>
            <Link
              className="HeaderMobileLink"
              to="/perc-nekustamo-ipasumu"
              onClick={closeMenu}
            >
              PĀRDOD ĪPAŠUMUS
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderMobile;
