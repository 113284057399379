import React from 'react';
import './CommunityValuesPage.css';
import Header from '../../components/header/Header';
import CommunityValuesIntro from '../../components/communityValuesIntro/CommunityValuesIntro';

const CommunityValuesPage = () => {
  return (
    <>
      <Header />
      <div className="CommunityValuesPage">
        <CommunityValuesIntro />
      </div>
    </>
  );
};

export default CommunityValuesPage;
