import React from 'react'
import './eventsItemMobile.css'
import ArrowIcon from '../../assets/ArrowIcon.svg';

const eventsItemMobile = ({ eventList }) => {
    const monthAbbreviations = {
        January: "JAN",
        February: "FEB",
        March: "MAR",
        April: "APR",
        May: "MAI",
        June: "JŪN",
        July: "JŪL",
        August: "AUG",
        September: "SEP",
        October: "OKT",
        November: "NOV",
        December: "DEC",
      };
    
      const dateStart = new Date(eventList.dateStart);
      const month = dateStart.toLocaleString("default", { month: "long" });
      const latvianAbbreviation = monthAbbreviations[month] || "???";
      const date = dateStart.toLocaleString("default", { day: "numeric" });
    
      const openEventLink = () => {
        if (eventList.linkEvent) {
          window.open(eventList.linkEvent, "_blank");
        }
      };
    
      return (
        <div className="EventItemMobile">
            <img
              src={eventList.image}
              alt={eventList.title}
            />
          <div className="EventsItemMobileDateContainer">
              <h3>{latvianAbbreviation}</h3>
              <h1>{date}</h1>
            </div>
          <div
            className="EventsItemMobileTitleContainer"
            onClick={openEventLink}
          >
            <h3>{eventList.title}</h3>
            <img className="EventsItemMobileTitleContainerArrow" src={ArrowIcon} alt="arrow" />
          </div>
        </div>
      );
    };

export default eventsItemMobile