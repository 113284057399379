import React from "react";
import "./CommunityValuesQuality.css";

const CommunityValuesQuality = () => {
  return (
    <>
      <div className="CommunityValuesQuality">
        <div className="CommunityValuesQualityImage">
          <img
            src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/values_kvalitativa.png"
            alt="Qualitative community"
          />
        </div>
        <div>
          <div className="CommunityValuesQualityHeading">
            <h2>KVALITATĪVA VIDE</h2>
          </div>
          <p>
            Doles sala ir izcilas kvalitatīvas vides piemērs. Šī sala izceļas ar
            skaistu un nevainojami saglabātu dabas vidi, kas ir viens no tās
            lielākajiem dārgumiem. Kvalitatīva vide Doles salā ne tikai nozīmē
            aizsargātu ainavu un neskartu dabas skaistumu, bet arī nodrošina
            iedzīvotājiem veselīgu un ilgtspējīgu dzīves stilu. Tīrs gaiss,
            nevainojami ūdens resursi un bagāta bioloģiskā daudzveidība ir
            pamatā tam, kā šī kopiena attīstās un audzina veselīgas paaudzes.
          </p>
        </div>
        <div>
          <p>
            Iedzīvotāji Doles salā ir apņēmušies ilgtspējīgi pārvaldīt savu vidi
            un izmantot tās resursus, saglabājot to nepiesārņotu un veselīgu.
            Viņi rūpējas par mežiem, laukiem un upes ekosistēmām, ievērojot
            ilgtermiņa ilgtspējīgas attīstības principus.
          </p>
        </div>
        <div>
          <p>
            Kopienas apņemšanās attiecībā pret kvalitatīvu vidi ietver arī
            izglītību un informācijas izplatīšanu par vides aizsardzību un
            ilgtspējīgas dzīves veidošanu. Saglabājot
            kvalitatīvu vidi, tiek nodrošināti labākas dzīves apstākļus ne tikai
            sev pašiem, bet arī nākamajām paaudzēm.
          </p>
        </div>
        <div>
          <p>
            Doles sala un tās kopienas ir izcils paraugs tam, kā ilgtspējīga
            attīstība un kvalitatīva vide var iet roku rokā, radot labklājību un
            labvēlīgu dzīves vidi. Šī sala ir inspirācija citām kopienām un
            sabiedrībai kopumā, parādot, ka aizsardzība un rūpēšanās par vidi ir
            ceļš uz ilgtspējīgu nākotni.
          </p>
        </div>
      </div>
    </>
  );
};

export default CommunityValuesQuality;
