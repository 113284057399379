import React, {useState} from 'react';
import './BuildingRulesAllowed.css';
import Road from '../../assets/Road.svg';
import CarPark from '../../assets/CarPark.svg';
import Trees from '../../assets/Trees.svg';
import Erosion from '../../assets/Erosion.svg';
import Electricity from '../../assets/Electricity.svg';
import Engineer from '../../assets/Engineer.svg';
import Arrow from '../../assets/ArrowIcon.svg';

const BuildingRulesAllowed = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [rotate, setRotate] = useState(0);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
    setRotate(rotate === 0 ? 90 : 0);
  };

  const arrowStyle = {
    transform: `rotate(${rotate}deg)`,
    transition: "transform 1s ease",
  };

  return (
    <>
      <div className="BuildingRulesSectionHeading__Allowed"
      onClick={toggleContentVisibility}>
        <h2>Kas ir atļauts visās funkcionālajās zonās?</h2>
        <img src={Arrow} alt="arrow" style={arrowStyle} />
      </div>
      <div className={`BuildingRulesSectionParagraphWrapper__Allowed ${
          isContentVisible ? "visible" : ""
        }`}>
        <div className="BuildingRulesSectionParagraph">
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Road} alt="Road icon" />
            <p>Transporta infrastruktūras objektu izbūve</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={CarPark} alt="Car park icon" />
            <p>Nepieciešamo transportlīdzekļu stāvvietu ierīkošana</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Trees} alt="Trees icon" />
            <p>Apstādījumu un teritorijas labiekārtojuma ierīkošana</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Electricity} alt="Electricity icon" />
            <p>Inženierkomunikāciju tīklu un objektu izbūve</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Erosion} alt="Erosion icon" />
            <p>
              Erozijas risku ierobežošanas, pretplūdu aizsardzības būvju un
              meliorācijas sistēmu izbūve
            </p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Engineer} alt="Engineer icon" />
            <p>
              Palīgēku un inženierbūvju būvniecība, kuras paredzētas, lai
              nodrošinātu pamatēku funkcionēšanu atbilstoši mērķim
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildingRulesAllowed;
