import React, { useState } from 'react';
import './BuildingRulesAgricultural.css';
import Engineer from '../../assets/Engineer.svg';
import Garage from '../../assets/Garage.svg';
import Villa from '../../assets/Villa.svg';
import Stadium from '../../assets/Stadium.svg';
import Shop from '../../assets/Shop.svg';
import Hotel from '../../assets/Hotel.svg';
import Outdoor from '../../assets/Outdoor.svg';
import CarWash from '../../assets/CarWash.svg';
import Arrow from '../../assets/ArrowIcon.svg';

const BuildingRulesAgricultural = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [rotate, setRotate] = useState(0);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
    setRotate(rotate === 0 ? 90 : 0);
  };

  const arrowStyle = {
    transform: `rotate(${rotate}deg)`,
    transition: "transform 1s ease",
  };

  return (
    <div>
      <div
        className="BuildingRulesSectionHeading__Agricultural"
        onClick={toggleContentVisibility}
      >
        <h2>Lauksaimniecības teritoriju apbūves noteikumi</h2>
        <img src={Arrow} alt="arrow" style={arrowStyle} />
      </div>
      <div
        className={`BuildingRulesSectionParagraphWrapper__Agricultural ${
          isContentVisible ? "visible" : ""
        }`}
      >
        <p className="BuildingRulesSectionParagraphBold">
          Lauksaimniecības teritorijā katrā zemes vienībā atļauta šādu ēku
          būvniecība:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Villa} alt="Villa icon" />
            <p>Viena vai divas viena dzīvokļa dzīvojamās mājas</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Garage} alt="Garage icon" />
            <p>Viena vai vairākas lauku saimniecību nedzīvojamas ēkas</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Shop} alt="Shop icon" />
            <p>Viena mazumtirdzniecības ēka</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Stadium} alt="Road icon" />
            <p>Viena sporta būve</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Hotel} alt="Hotel icon" />
            <p>
              Viena vai vairākas viesnīcu ēkas, vai arī viena vai vairākas cita
              veida ēkas atpūtai un brīvdienu pavadīšanai
            </p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Outdoor} alt="Latern and chair icon" />
            <p>
              Viena vai vairākas ar publiskās ārtelpas labiekārtošanu saistītas
              būves
            </p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={CarWash} alt="Road icon" />
            <p>
              Viena rūpnieciskās ražošanas ēka, kas paredzēta auto remonta
              darbnīcām vai auto mazgātuvei
            </p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Engineer} alt="Road icon" />
            <p>
              Objekti, kas nodrošina lauksaimniecības un nelauksaimniecisko
              produktu ražošanu un ar to saistīto pakalpojumu sniegšanu
            </p>
          </div>
        </div>
        <p className="BuildingRulesSectionParagraphBold">
          Lauku zemju teritorijā ievēro šādas apbūves prasības:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <p>Jauni veidojamas zemes platība ir vismaz 2 ha</p>
          <p>Maksimālais stāvu skaits - 2 stāvi</p>
          <p>Maksimālais augstums - 8 metri</p>
          <p>Arhitektoniska akcenta augstums 12 metri</p>
          <p>
            Ēkas ir jāizvieto vienotā pagalmā, kura platība nepārsniedz 0,5 ha,
            bet ne vairāk kā 25% no kopējās zemesgabala platības
          </p>
          <p>Pagalma apbūves blīvums līdz 30%</p>
        </div>
        <p className="BuildingRulesSectionParagraphBold">
          Mājlopiem un mājputniem paredzētas ēkas un būves jābūvē:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <p>Vismaz 30 m attālumā no zemes vienības robežas</p>
          <p>
            Zemes vienības teritorijas daļā, kura neatrodas starp valsts vai
            pašvaldības autoceļu un dzīvojamām ēkām
          </p>
        </div>
      </div>
    </div>
  );
};

export default BuildingRulesAgricultural;
