import React from 'react';
import './EventsBridge.css';

const EventsBridge = () => {
  return (
    <div className='EventsBridge'>
        <div className='EventsBridgeContainer'>
            <h1>PASĀKUMU KALENDĀRS</h1>
        </div>
    </div>
  )
}

export default EventsBridge