import React from "react";
import "./ActivityPage.css";
import Header from "../../components/header/Header";
import WhattodoPageMap from "../../components/whattodoPageMap/WhattodoPageMap";
import WhattodoPageMade from "../../components/whattodoPageMade/WhattodoPageMade"
import WhattodoPageActivities from "../../components/whattodoPageActivities/WhattodoPageActivities";
import WhattodoPageNature from "../../components/whattodoPageNature/WhattodoPageNature";
import WhattodoPageVenues from "../../components/whattodoPageVenues/WhattodoPageVenues";
import WhattodoPageHistory from "../../components/whattodoPageHistory/WhattodoPageHistory";
import WhattodoPageFood from "../../components/whattodoPageFood/WhattodoPageFood";
import WhattodoPageMadeMobile from "../../components/whattodoPageMadeMobile/WhattodoPageMadeMobile";
import WhattodoPageActivitiesMobile from "../../components/whattodoPageActivitiesMobile/WhattodoPageActivitiesMobile"
import WhattodoPageVenuesMobile from "../../components/whattodoPageVenuesMobile/WhattodoPageVenuesMobile"
import WhattodoPageNatureMobile from "../../components/whattodoPageNatureMobile/WhattodoPageNatureMobile"
import WhattodoPageHistoryMobile from "../../components/whattodoPageHistoryMobile/WhattodoPageHistoryMobile"
import WhattodoPageFoodMobile from "../../components/whattodoPageFoodMobile/WhattodoPageFoodMobile"
import WhattodoPageIntroMobile from "../../components/whattodoPageIntroMobile/WhattodoPageIntroMobile";

const ActivityPage = () => {
  return (
    <div className="ActivityPage">
      <Header />
      <WhattodoPageIntroMobile />
      <WhattodoPageMade />
      <WhattodoPageMadeMobile />
      <WhattodoPageActivities />
      <WhattodoPageActivitiesMobile />
      <WhattodoPageVenues />
      <WhattodoPageVenuesMobile />
      <WhattodoPageNature />
      <WhattodoPageNatureMobile />
      <WhattodoPageHistory />
      <WhattodoPageHistoryMobile />
      <WhattodoPageFood />
      <WhattodoPageFoodMobile />
    </div>
  );
};

export default ActivityPage;
