import "./WhattodoPageMadeMobile.css";
import React, { useReducer, useEffect, useState } from "react";
import axios from "axios";
import ArrowIcon from "../../assets/ArrowIcon.png";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const WhattodoPageMadeMobile = () => {
  const [whattodoItems, dispatch] = useReducer(reducer, initialState);
  const [whattodoItemsMade, setWhattodoItemsMade] = useState([]);

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `https://www.doles-sala.lv/object/get-all-objects`
        );
        dispatch({ type: "SUCCESS", payload: response.data });
        const filteredWhattodoItems = response.data.filter(
          (whattodoItems) => whattodoItems.type === "radits"
        );
        setWhattodoItemsMade(filteredWhattodoItems);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="WhattodoPageMadeMobileWrapper">
      {whattodoItemsMade.map((whattodoItems) => (
        <div className="WhattodoPageMadeMobile">
          <img src={whattodoItems.image} alt={whattodoItems.title} />
          <h2>{whattodoItems.title}</h2>
          <div className="WhattodoPageMadeMobileContentTextParagraph">
            <p>{whattodoItems.description[0]}</p>
            <p>{whattodoItems.description[1]}</p>
            <p>{whattodoItems.description[2]}</p>
          </div>
          <a href={whattodoItems.link} target="_blank" rel="noreferrer">
            <div className="WhattodoPageMadeMobileContentLink">
              <img src={ArrowIcon} alt="arrow" />
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default WhattodoPageMadeMobile;
