import React,{ useRef } from 'react';
import './AccommodationPage.css';
import Header from '../../components/header/Header';
import AccommodationPageList from '../../components/accommodationPageList/AccommodationPageList';
import AccommodationPageMap from '../../components/accommodationPageMap/AccommodationPageMap';

const AccommodationPage = () => {
  const listRef = useRef();

  const scrollToSection = (sectionId) => {
    const section = listRef.current.querySelector(`#${sectionId}`);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div>
    <Header />
    <div className='AccommodationPage'>
      <AccommodationPageMap scrollToSection={scrollToSection} />
      <AccommodationPageList ref={listRef} />
    </div>
  </div>
  )
}

export default AccommodationPage