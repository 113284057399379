import React from "react";
import "./Footer.css";
import LogoFooter from "../../assets/Dolessala_logo_WEB_footer.svg";
import TwitterLogo from "../../assets/Twitter_logo-03.svg";
import FBLogo from "../../assets/FB_logo-04.svg";
import IGLogo from "../../assets/IG_logo-02.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div id="footer" className="Footer">
      <div className="FooterContainerWrapper">
        <div className="FooterContainer">
          <div className="FooterId">
            <div className="FooterIdLogoWrapper">
              <Link to="/">
                <img src={LogoFooter} className="FooterLogo" alt="logo"></img>
              </Link>
            </div>
            <div>
              <h4>DOLES SALA</h4>
              <ul>
                <li>VIETA, KUR SAJUST SEVI</li>
              </ul>
            </div>
          </div>
          <div className="FooterPagemap">
            <div className="FooterPagemapSection">
              <h4>KOPIENA</h4>
              <ul>
                {/*<Link className="FooterLink" to="/par-mums">
                  <li>PAR MUMS</li>
  </Link>*/}
                <Link className="FooterLink" to="/kopienas-vertibas">
                  <li>KOPIENAS VĒRTĪBAS</li>
                </Link>
              </ul>
            </div>
            <div className="FooterPagemapSection">
              <h4>APMEKLĒ</h4>
              <ul>
                <Link className="FooterLink" to="/pasakumi">
                  <li>PASĀKUMI</li>
                </Link>
                <Link className="FooterLink" to="/naktsmitnes">
                  <li>KUR NAKŠŅOT?</li>
                </Link>
                <Link className="FooterLink" to="/ko-darit">
                  <li>KO DARĪT?</li>
                </Link>
              </ul>
            </div>
            <div className="FooterPagemapSection">
              <h4>DZĪVO</h4>
              <ul>
                <Link className="FooterLink" to="/apbuve">
                  <li>DZĪVE UZ SALAS</li>
                </Link>
                <Link className="FooterLink" to="/perc-nekustamo-ipasumu">
                  <li>PĀRDOD ĪPAŠUMUS</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="FooterFollow">
            <h4>SEKO MUMS</h4>
            <ul>
              <li>
                <div>
                  <img src={TwitterLogo} alt="twitter"></img>
                </div>
                TWITTER
              </li>
              <li>
                <div>
                  <img src={IGLogo} alt="instagram"></img>
                </div>
                INSTAGRAM
              </li>
              <li>
                <div>
                  <img src={FBLogo} alt="facebook"></img>
                </div>
                FACEBOOK
              </li>
            </ul>
          </div>
          <div className="FooterContact">
            <h4>SAZINIES</h4>
            <ul>
              <li>BIEDRĪBA DOLES SALA</li>
              <li>Reģ.Nr.: 400008316572</li>
              <li>biedriba.dolessala@gmail.com</li>
              <li>Doleskrasti1, Salaspils pag., Salaspils nov., LV2121</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
