import React, { useReducer, useState, useEffect } from "react";
import "./HomepageEventSectionEventListMobile.css";
import HomepageEventSectionEventItem from "../homepageEventSectionEventItem/HomepageEventSectionEventItem";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../node_modules/swiper/swiper-bundle.min.css";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const getRandomEvents = (events, count) => {
  const randomEvents = [...events].sort(() => 0.5 - Math.random());
  return randomEvents.slice(0, count);
};

const HomepageEventSectionEventListMobile = () => {
  const [events, dispatch] = useReducer(reducer, initialState);
  const [upcommingEvents, setUpcomingEvents] = useState([]);

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `https://www.doles-sala.lv/event/get-all-events`
        );
        dispatch({ type: "SUCCESS", payload: response.data });

        const randomUpcomingEvents = getRandomEvents(response.data, 3);
        setUpcomingEvents(randomUpcomingEvents);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="HomepageEventSectionEventListMobile">
      <Swiper
        spaceBetween={50}
        slidesPerView={1} // Display one slide at a time
      >
        {upcommingEvents.map((event) => (
          <SwiperSlide key={event.id}>
            <HomepageEventSectionEventItem
              homepageEventSectionEventList={event}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default HomepageEventSectionEventListMobile;
