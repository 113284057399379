import React from "react";
import "./RealEstateInvestItem.css";

const RealEstateInvestItem = ({ realEstateInvest }) => {
  return (
    <>
      <div className="RealEstateInvestItem">
        <h2>{realEstateInvest.address}</h2>
        <h3 className="RealEstateInvestItemTextStyle">
          {realEstateInvest.title}
        </h3>
        <div className="RealEstateInvestItemImage">
          <img src={realEstateInvest.image} alt="house"/>
        </div>
        <div className="RealEstateInvestItemArea">
          <h3 className="RealEstateInvestItemAreaTextStyle">
            {realEstateInvest.areaHouse} m<sup>2</sup>
          </h3>
        </div>
        <div className="RealEstateInvestItemPrice">
          <h2>{realEstateInvest.price} €</h2>
        </div>
      </div>
    </>
  );
};

export default RealEstateInvestItem;
