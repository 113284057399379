import React from "react";
import "./CommunityValuesStorm.css";

const CommunityValuesStorm = () => {
  return (
    <div className="CommunityValuesStorm">
      <div>
        <div className="CommunityValuesStormHeading">
          <h2>VĒTRAS ACS</h2>
        </div>
        <p>
          Doles sala, skaistā un mierīgā vieta, kas atrodas netālu no lielās
          pilsētas burzmas, ir īsta oāze mieram un harmonijai. Šajā ainaviskajā
          vietā, kur dabas skaistums apvienojas ar kopienas dzīvi, viena no
          galvenajām vērtībām ir pazīstama kā "Vētras acs". Kopiena Doles salā
          ir iemācījusies cienīt mieru un izklaides brīžus, pat tādā laikā, kad
          visā pasaulē valda trauksme un straujš dzīves ritējums.
        </p>
      </div>
      <div className="CommunityValuesStormImage">
        <img
          src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/values_vetra.png"
          alt="Peacful woman in the forest"
        />
      </div>
      <div>
        <p>
          Šī vērtība ir kļuvusi par dzīvesveida
          sastāvdaļu. Ikdienas steiga un stress, kas citur var būt tik bieži
          sastopami, šeit ir palikuši pagātnē. Iegrimuši ainaviskā skaistumā un
          salas klusumā, Doles salas iedzīvotāji izbauda dzīvi, kurā ir laiks
          domāt, baudīt dabu un iepazīties ar citiem cilvēkiem.
        </p>
      </div>
      <div>
        <p>
          Kaut arī pasaulē var būt steidzīga dzīves ritma, Doles sala paliek
          nemainīga kā mierīga un stabila vieta, kur ļauties atpūtai,
          atjaunoties un sajust dziļāku saikni ar apkārtējo dabu.
        </p>
      </div>
      <div>
        <p>
          Šī vieta sniedz iespēju izjust "Vētras aci" vidē, kur dominē miers un
          izlaidība.
          Doles sala un tās iedzīvotāji ir piemērs tam, ka mierpilna dzīve ir
          iespējama blakus pat visnepiemērotākajiem apstākļiem un ka svarīgākais ir
          saglabāt saskanību ar savu iekšējo līdzsvaru.
        </p>
      </div>
    </div>
  );
};

export default CommunityValuesStorm;
