import React from "react"
import "./HomepageEventSectionButton.css"
import { Link } from "react-router-dom"

const HomepageEventSectionButton = () => {
  return (
    <div className="HomepageEventSectionButton">
      <Link to="/pasakumi">
        <input type="button" value="VISI PASĀKUMI"></input>
      </Link>
    </div>
  );
};

export default HomepageEventSectionButton;
