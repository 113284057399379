import React from 'react'
import './HomepageEventsSection.css'
import HomepageEventSectionTitle from '../homepageEventSectionTitle/HomepageEventSectionTitle'
import HomepageEventSectionEventList from '../homepageEventSectionEventList/HomepageEventSectionEventList'
import HomepageEventSectionButton from '../homepageEventSectionButton/HomepageEventSectionButton'
import HomepageEventSectionEventListMobile from '../homepageEventSectionEventListMobile/HomepageEventSectionEventListMobile'

const HomepageEventsSection = () => {
    return (
        <div className='EventsSection'>
            <HomepageEventSectionTitle />
            <HomepageEventSectionEventList />
            <HomepageEventSectionEventListMobile />
            <HomepageEventSectionButton />
        </div>
    )
}

export default HomepageEventsSection