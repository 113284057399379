import React, { useState } from "react"
import "./Header.css"
import HeaderLogo from "../../assets/Dolessala_logo_WEB-02.svg"
import { Link } from "react-router-dom"

const Header = () => {
  const [isKopienaOpen, setKopienaOpen] = useState(false);
  const [isApmekleOpen, setApmekleOpen] = useState(false);
  const [isDzivoOpen, setDzivoOpen] = useState(false);

  const handleKopienaToggle = () => {
    setKopienaOpen(!isKopienaOpen);
  };
  const handleApmekleToggle = () => {
    setApmekleOpen(!isApmekleOpen);
  };
  const handleDzivoToggle = () => {
    setDzivoOpen(!isDzivoOpen);
  };

  return (
    <header className="Header">
      <div className="HeaderContainerWrapper">
        <div className="HeaderLogoWrapper">
          <Link to="/">
            <img src={HeaderLogo} className="HeaderLogo" alt="logo" />
          </Link>
        </div>
        <div className="HeaderNavigation">
          <ul>
            <li
              onMouseEnter={handleKopienaToggle}
              onMouseLeave={handleKopienaToggle}
            >
              KOPIENA
              {isKopienaOpen && (
                <div className="DropdownMenu">
                  {/*<div className="HeaderLinkWrapper">
                    <Link className="HeaderLink" to="/par-mums">
                      PAR MUMS
                    </Link>
              </div>*/}
                  <div className="HeaderLinkWrapper">
                    <Link className="HeaderLink" to="/kopienas-vertibas">
                      KOPIENAS VĒRTIBAS
                    </Link>
                  </div>
                </div>
              )}
            </li>
            <li
              onMouseEnter={handleApmekleToggle}
              onMouseLeave={handleApmekleToggle}
            >
              APMEKLĒ
              {isApmekleOpen && (
                <div className="DropdownMenu">
                  <div className="HeaderLinkWrapper">
                    <Link className="HeaderLink" to="/pasakumi">
                      PASĀKUMI
                    </Link>
                  </div>
                  <div className="HeaderLinkWrapper">
                    <Link className="HeaderLink" to="/naktsmitnes">
                      KUR NAKŠŅOT?
                    </Link>
                  </div>
                  <div className="HeaderLinkWrapper">
                    <Link className="HeaderLink" to="/ko-darit">
                      KO DARĪT?
                    </Link>
                  </div>
                </div>
              )}
            </li>
            <li
              onMouseEnter={handleDzivoToggle}
              onMouseLeave={handleDzivoToggle}
            >
              DZĪVO
              {isDzivoOpen && (
                <div className="DropdownMenu">
                  <div className="HeaderLinkWrapper">
                    <Link className="HeaderLink" to="/apbuve">
                      DZĪVE UZ SALAS
                    </Link>
                  </div>
                  <div className="HeaderLinkWrapper">
                    <Link className="HeaderLink" to="/perc-nekustamo-ipasumu">
                      PĀRDOD ĪPAŠUMUS
                    </Link>
                  </div>
                </div>
              )}
            </li>
            <Link to="/ienakt">
              <input type="button" value="IENĀKT"></input>
            </Link>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
