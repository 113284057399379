import React from "react";
//import RealEstateHero from '../../assets/RealEstateHero.jpg';
import "./RealEstateIntro.css";
import RealEstateMap from "../../assets/RealEstateIntroMap.png";

const RealEstateIntro = () => {
  return (
    <div className="RealEstateIntro">
      <img alt="hero" className="RealEstateIntroMap" src={RealEstateMap} />
      <div className="RealEstateIntroContent">
        <div className="RealEstateIntroContentHeading">
          <h1>VĒLIES DZĪVOT</h1>
          <h1>DOLES SALĀ?</h1>
        </div>
        <div className="RealEstateIntroContentText">
          <h3>
            Uzzini aktuālos nekustamo īpašumu piedāvājumus un atrodi savu sapņu
            īpašumu, atbilstoši savām vēlmēm un vajadzībām - dzīvošanai gatavu
            māju, zemes gabalu vai investīciju objektu.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default RealEstateIntro;
