import "./App.css";
import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from "react-router-dom";
import Homepage from "./pages/homepage/Homepage";
import Footer from "./components/footer/Footer";
import AboutUsPage from "./pages/aboutUsPage/AboutUsPage";
import CommunityValuesPage from "./pages/communityValuesPage/CommunityValuesPage";
import EventsPage from "./pages/eventsPage/EventsPage";
import AccommodationPage from "./pages/accommodationPage/AccommodationPage";
import ActivityPage from "./pages/activityPage/ActivityPage";
import BuildingPage from "./pages/buildingPage/BuildingPage";
import RealEstatePage from "./pages/realEstatePage/RealEstatePage";
import LoginPage from "./pages/loginPage/LoginPage";
import TowBarPage from "./pages/towBarPage/TowBarPage";
import HeaderMobile from "./components/headerMobile/HeaderMobile";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <>
      <HeaderMobile />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Homepage />} />
        {/*<Route path="/par-mums" element={<AboutUsPage />} />*/}
        <Route path="/kopienas-vertibas" element={<CommunityValuesPage />} />
        <Route path="/pasakumi" element={<EventsPage />} />
        <Route path="/naktsmitnes" element={<AccommodationPage />} />
        <Route path="/ko-darit" element={<ActivityPage />} />
        <Route path="/apbuve" element={<BuildingPage />} />
        <Route path="/perc-nekustamo-ipasumu" element={<RealEstatePage />} />
        <Route path="/ienakt" element={<LoginPage />} />
        <Route path="/blogs/tauvas-josla" element={<TowBarPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
