import React, { useState, useEffect, useReducer } from 'react';
import './EventsList.css';
import EventsItem from '../eventsItem/EventsItem';
import EventsItemMobile from '../eventsItemMobile/eventsItemMobile';
import axios from 'axios';

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const EventsList = () => {
  const [events, dispatch] = useReducer(reducer, initialState);
  const [upcommingEvents, setUpcommingEvents] = useState([]);

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `https://www.doles-sala.lv/event/get-all-events`
        );
        dispatch({ type: "SUCCESS", payload: response.data });

        const filteredEvents = response.data.sort((a, b) =>
        new Date(a.dateStart) - new Date(b.dateStart)
      );
        setUpcommingEvents(filteredEvents);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="EventsList">
      {upcommingEvents.map((event) => (
        <EventsItem key={event.id} eventList={event} />
      ))}
      {upcommingEvents.map((event) => (
        <EventsItemMobile key={event.id} eventList={event} />
      ))}
    </div>
  );
};

export default EventsList;
