import React, { useReducer, useEffect, useState } from "react";
import "./WhattodoPageHistory.css";
import axios from "axios";
import ArrowIcon from "../../assets/ArrowIcon.png";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const WhattodoPageHistory = () => {
  const [whattodoItems, dispatch] = useReducer(reducer, initialState);
  const [whattodoItemsHistory, setWhattodoItemsHistory] = useState([]);

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `https://www.doles-sala.lv/object/get-all-objects`
        );
        dispatch({ type: "SUCCESS", payload: response.data });
        const filteredWhattodoItems = response.data.filter(
          (whattodoItems) => whattodoItems.type === "kulturvesture"
        );
        setWhattodoItemsHistory(filteredWhattodoItems);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="WhattodoPageHistory">
      <div className="WhattodoPageHistoryBackgroundImage">
        <img
          src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/WhattodoPageHistory.jpg"
          alt="bicycle"
        />
      </div>
      <div className="WhattodoPageHistoryTitle">kultūrvēsture</div>
      <div className="WhattodoPageHistoryContentWrapper">
        {whattodoItemsHistory.map((whattodoItems) => (
          <div className="WhattodoPageHistoryContent" key={whattodoItems.id}>
            <img src={whattodoItems.image} alt={whattodoItems.title} />
            <div className="WhattodoPageHistoryContentText">
              <h3>{whattodoItems.title}</h3>
              <div className="WhattodoPageHistoryContentTextParagraph">
                <p>{whattodoItems.description[0]}</p>
                <p>{whattodoItems.description[1]}</p>
                <p>{whattodoItems.description[2]}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhattodoPageHistory;
