import React from 'react';
import './HomepageHeroSection.css';
import HeroImage from '../../assets/HomepageHome_Hero.png';
import Squirrel from '../../assets/Squirrel.png';
import Woodpecker from '../../assets/Woodpecker.png';
import HomepageHeroSectionContent from '../homepageHeroSectionContent/HomepageHeroSectionContent';

const HomepageHeroSection = () => {
  return (
    <div className="HomepageHeroSection">
      <img alt="hero" className="HomepageHome_Hero_Image" src={HeroImage} />
      <div className="HomepageHome_Hero_Layer"></div>
      <div className="HomepageHome">
        <HomepageHeroSectionContent />
        <div className="HomepageHomeAccentSquirrelContainer">
          <img
            className="HomepageHomeAccentSquirrel"
            src={Squirrel}
            alt="squirrel"
          />
        </div>
        <div className="HomepageHomeAccentWoodpeckerContainer">
          <img
            className="HomepageHomeAccentWoodpecker"
            src={Woodpecker}
            alt="woodpecker"
          />
        </div>
      </div>
    </div>
  );
};

export default HomepageHeroSection;
