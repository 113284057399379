import React from "react";
import "./AccommodationPageItemMobile.css";
import ArrowIcon from "../../assets/ArrowIcon.svg";

const AccommodationPageItemMobile = ({
  heading,
  title,
  description1,
  description2,
  description3,
  image,
  link,
}) => {
  const openEventLink = () => {
    if (link) {
      window.open(link, "_blank");
    }
  };

  return (
    <div className="AccommodationPageItemMobile">
      <img src={image} alt={title} />
      <div className="AccommodationPageItemMobileInfoContainer">
        <h3>{heading}</h3>
        <h1>{title}</h1>
      </div>
      <div
        className="AccommodotionPageItemMobileLinkContainer"
        onClick={openEventLink}
      >
        <h3>REZERVĒT</h3>
        <img
          className="AccommodotionPageItemMobileLinkContainerArrow"
          src={ArrowIcon}
          alt="arrow"
        />
      </div>
    </div>
  );
};

export default AccommodationPageItemMobile;
