import React from 'react';
import './BuildingRules.css';
import BuildingRulesIntro from '../buildingRulesIntro/BuildingRulesIntro';
import BuildingRulesMap from '../buildingRulesMap/BuildingRulesMap';
import BuildingRulesAllowed from '../buildingRulesAllowed/BuildingRulesAllowed';
import BuildingRulesProhibited from '../buildingRulesProhibited/BuildingRulesProhibited';
import BuildingRulesAgricultural from '../buildingRulesAgricultural/BuildingRulesAgricultural';
import BuildingRulesSummerhouse from '../buildingRulesSummerhouse/BuildingRulesSummerhouse';
import BuildingRulesForest from '../buildingRulesForest/BuildingRulesForest';
import BuildingRulesFactory from '../buildingRulesFactory/BuildingRulesFactory';
import BuildingRulesWater from '../buildingRulesWater/BuildingRulesWater';

const BuildingRules = () => {
  return (
    <div className="BuildingRules">
      <BuildingRulesIntro />
      <BuildingRulesMap />
      <BuildingRulesAllowed />
      <BuildingRulesProhibited />
      <BuildingRulesAgricultural />
      <BuildingRulesSummerhouse />
      <BuildingRulesForest />
      <BuildingRulesFactory />
      <BuildingRulesWater />
    </div>
  );
};

export default BuildingRules;
