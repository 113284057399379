import React from 'react';
import './Homepage.css';
import Header from '../../components/header/Header.jsx';
import HomepageHeroSection from '../../components/homepageHeroSection/HomepageHeroSection.jsx';
import HomepageIntroSection from '../../components/homepageIntroSection/HomepageIntroSection.jsx';
import HomepageEventsSection from '../../components/homepageEventsSection/HomepageEventsSection.jsx';
import BlogSection from '../../components/homepageBlogSection/HomepageBlogSection.jsx';

const Homepage = () => {
  return (
    <div className='Homepage'>
      <Header />
      <HomepageHeroSection />
      <HomepageIntroSection />
      <HomepageEventsSection />
      <BlogSection />
    </div>
  )
}

export default Homepage