import React, {useState} from 'react';
import './BuildingRulesWater.css';
import Arrow from '../../assets/ArrowIcon.svg';

const BuildingRulesWater = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [rotate, setRotate] = useState(0);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
    setRotate(rotate === 0 ? 90 : 0);
  };

  const arrowStyle = {
    transform: `rotate(${rotate}deg)`,
    transition: "transform 1s ease",
  };

  return (
    <div>
        <div className="BuildingRulesSectionHeading__Water"
        onClick={toggleContentVisibility}>
        <h2>Ūdeņu teritoriju apbūves noteikumi</h2>
        <img src={Arrow} alt="arrow" style={arrowStyle} />
      </div>
      <div className={`BuildingRulesSectionParagraphWrapper__Water ${
          isContentVisible ? "visible" : ""
        }`}>
        <p className="BuildingRulesSectionParagraphBold">
          Ūdeņu teritorijās ir atļauta šādu ēku būvniecība:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <p>Ar transporta infrastruktūru saistītas būves</p>
        </div>
      </div>
    </div>
  )
}

export default BuildingRulesWater