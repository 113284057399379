import React from 'react'
import './HomepageBlogSection.css'
import HomepageBlogTowBar from '../homepageBlogTowBar/HomepageBlogTowBar'

const HomepageBlogSection = () => {
  return (
    <div className='BlogSection'>
      <HomepageBlogTowBar />
    </div>
  )
}

export default HomepageBlogSection