import React, { useState, useRef } from 'react';
import './Login.css';
import Lock from '../../assets/Lock.svg';
import Mail from '../../assets/Mail.svg';

const Login = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const userNameRef = useRef(null);
  const passwordRef = useRef(null);

  const handleClick = () => {
    // Check for login conditions here (you can replace this with your actual logic)
    const isLoginSuccessful = false; // Replace with your login logic

    if (!isLoginSuccessful) {
      setErrorMessage("Lietotājvārds vai parole nav pareiza!");
      setIsErrorVisible(true);
      userNameRef.current.value = "";
      passwordRef.current.value = "";
    } else {
      setErrorMessage("");
      setIsErrorVisible(false);
    }
  };

  return (
    <>
      <div className="UserLoginFormWrapper">
        <h1 className="UserLoginTitle">Autorizēties</h1>
        <p className="UserLoginText">Ieeja tikai reģistrētiem lietotājiem</p>
        <form className="UserLoginForm">
          <div className="UserLoginFormUser">
            <img src={Mail} alt="MailIcon"/>
            <input type="text" id="UserName" placeholder="Epasts" ref={userNameRef}></input>
          </div>
          <div className="UserLoginFormPassword">
            <img src={Lock} alt="LockIcon" />
            <input type="password" id="Password" placeholder="Parole" ref={passwordRef}></input>
          </div>
          <p className="UserLoginErrorMessage">{errorMessage}</p>
        </form>
        <button className="UserLoginButton" onClick={handleClick}>
          IENĀKT
        </button>
        {/*<button className="UserRegistrationButton">REĢISTRĒTIES</button>*/}
      </div>
    </>
  );
};

export default Login;
