import React from "react";
import "./RealEstateHouseItemModal.css";
import CloseIcon from "../../assets/CloseIcon.svg";
import PhoneIcon from "../../assets/PhoneIcon.svg";
import MailIcon from "../../assets/MailGrey.svg";

const RealEstateHouseItemModal = ({ onClose, realEstateHouses, isOpen }) => {
  const handleModalClose = (event) => {
    event.stopPropagation(); // Prevent event propagation
    onClose();
  };

  return (
    <div className={`RealEstateHouseItemModal ${isOpen ? "open" : ""}`}>
      <div className="RealEstateHouseItemModalWrapper">
        <div className="RealEstateHouseItemModalCloseIcon" onClick={handleModalClose}>
          <img src={CloseIcon} alt="close icon" />
        </div>
        <div className="RealEstateHouseItemModalContent">
          <h3>Atļautā izmantošana</h3>
          <p>{realEstateHouses.purpose[0]}</p>
          <p>{realEstateHouses.purpose[1]}</p>
          <h3> Zemes platība</h3>
          <p>{realEstateHouses.areaLand} m<sup>2</sup></p>
        </div>
        <div>
          <h3>Zemes gabals atrodas pie ūdens</h3>
          <p>{realEstateHouses.riverside}</p>
        </div>
        <div>
          <h3>Kontaktinformācija</h3>
          <div className="RealEstateHouseItemModalContactPhoneWrapper">
            <div className="RealEstateHouseItemModalPhoneIcon">
              <img src={PhoneIcon} alt="phone" />
            </div>
            <p>{realEstateHouses.phoneNumber}</p>
          </div>
          <div className="RealEstateHouseItemModalContactPhoneWrapper">
            <div className="RealEstateHouseItemModalMailIcon">
              <img src={MailIcon} alt="phone" />
            </div>
            <p>{realEstateHouses.email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RealEstateHouseItemModal;
