import React, { useState, useReducer, useEffect } from "react";
import "./RealEstateInvest.css";
import axios from "axios";
import RealEstateHouseItem from "../realEstateHouseItem/RealEstateHouseItem";
import RealEstateHouseItemModal from "../realEstateHouseItemModal/RealEstateHouseItemModal";
import RealEstateInvestItem from "../realEstateInvestItem/RealEstateInvestItem";
import RealEstateInvestItemModal from "../realEstateInvestItemModal/RealEstateInvestItemModal";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "../../../node_modules/swiper/swiper.css";


// import required modules
import { Pagination } from "swiper/modules";
import RealEstateInvestItemMobile from "../realEstateInvestItemMobile/RealEstateInvestItemMobile";

const initialState = {
  isLoading: false,
  data: [],
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOADING":
      return { ...state, isLoading: true };
    case "SUCCESS":
      return { ...state, data: action.payload, isLoading: false };
    case "FAILURE":
      return { ...state, error: action.payload, isLoading: false };
    default:
      return state;
  }
};

const RealEstateInvest = () => {
  const [realEstates, dispatch] = useReducer(reducer, initialState);
  const [realEstateInvest, setRealEstateInvest] = useState([]);
  const [selectedRealEstate, setSelectedRealEstate] = useState(null); // New state for modal specific data
  const [isOpenModal, setIsOpenModal] = useState(false);

  const openModal = (realEstate) => {
    setSelectedRealEstate(realEstate);
    setIsOpenModal(true);
  };
  const closeModal = (realEstate) => {
    setSelectedRealEstate(realEstate);
    setIsOpenModal(false);
  };

  useEffect(() => {
    const handleFetch = async () => {
      dispatch({ type: "LOADING" });
      try {
        const response = await axios.get(
          `https://www.doles-sala.lv/realEstate/get-all-realEstates`
        );
        dispatch({ type: "SUCCESS", payload: response.data });
        const filteredRealEstateHouses = response.data.filter(
          (realEstate) => realEstate.type === "investiciju"
        );
        setRealEstateInvest(filteredRealEstateHouses);
      } catch (err) {
        dispatch({ type: "FAILURE", payload: err.message });
      }
    };

    handleFetch();
  }, []);

  return (
    <div className="RealEstateInvest">
      <div className="RealEstateInvestContentWrapper">
        <div className="RealEstateInvestContentAddsIntro">
          <h2>
            Investīcijas Doles salā: Nekustamie īpašumi kā perspektīvs izaugsmes
            potenciāls
          </h2>
          <p>
            Kāds ir Doles salas izaugsmes potenciāls un kāpēc šī atrašanās vieta
            ir pievilcīga investīcijām? Ainaviskais skaistums padara Doles salu
            par pievilcīgu galamērķi tūristiem un tiem, kuri meklē
            neaizmirstamas atpūtas vietas. Piekļuve upei padara salu īpaši
            piemērotu ūdens aktivitātēm, tai skaitā makšķerēšanai. Kā arī sala
            ir bagāta ar kultūrvēsturiskiem pieminekļiem, muzeju un kultūras
            pasākumiem, kas piedāvā iepazīties ar salas un Latvijas vēsturi.
          </p>
          <p>
            Šajā tīmekļa vietnes sadaļā tiek sniegta informācija par
            pieejamajiem investīciju objektiem un to izmantošanas iespējām.
          </p>
        </div>
        <div className="RealEstateInvestContentAddsWrapper">
          <Swiper
            slidesPerView={4}
            spaceBetween={0}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            {realEstateInvest.map((realEstate) => (
              <SwiperSlide
                key={realEstate.id}
                onClick={() => openModal(realEstate)}
              >
                <RealEstateInvestItem realEstateInvest={realEstate} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        {realEstateInvest.map((realEstate) => (
          <div key={realEstate.id} onClick={() => openModal(realEstate)}>
            <RealEstateInvestItemMobile realEstateInvest={realEstate} />
          </div>
        ))}
        {selectedRealEstate && ( // Show the modal when selectedRealEstate is not null
          <RealEstateInvestItemModal
            onClose={closeModal}
            realEstateInvest={selectedRealEstate}
            isOpen={isOpenModal} // Pass the modal open state
          />
        )}
      </div>
    </div>
  );
};

export default RealEstateInvest;
