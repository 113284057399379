import React from "react";
import "./TowBarPageContent.css";

const TowBarPageContent = () => {
  return (
    <div className="TowBarPageContent">
      <h1>TAUVAS JOSLA</h1>
      <p>
        Tauvas josla ir sauszemes josla gar ūdeņu krastu, kas paredzēta ar zveju
        vai kuģošanu saistītām darbībām un kājāmgājējiem.
      </p>
      <p>Dabiskās tauvas joslas platums ir 10 metru gar Daugavas krastiem.</p>
      <p>Un tas tiek skaitīts šādi:</p>
      <ul className="TowBarPageContentList">
        <li className="TowBarContentContainer">
          <div className="TowBarContentContainerBulletin">
            <div className="TowBarContentContainerBulletinCircle">1</div>
          </div>
          <p>gar upju un ezeru lēzeniem krastiem — no normālās ūdenslīnijas;</p>
        </li>
        <li className="TowBarContentContainer">
          <div className="TowBarContentContainerBulletin">
            <div className="TowBarContentContainerBulletinCircle">2</div>
          </div>
          <p>
            gar kraujiem krastiem — no krasta nogāžu augšmalas, turklāt tauvas
            joslas platumā ietilpst arī zeme no ūdenslīmeņa līdz krasta nogāzei
            un pati nogāze;
          </p>
        </li>
      </ul>
      <p>
        Pie ezeriem un upēm par piekļuves ceļiem jārūpējas pašvaldībām, un te ir
        citādi – netiek pieprasīts, lai ceļi būtu ik pēc kilometra. Tāpēc
        jāpaļaujas vien uz to, ka, piemēram, pie Daugavas, kuras krastos
        pašvaldības mainās cita pēc citas, ir vismaz viens ceļš katras
        pašvaldības teritorijā.
      </p>
      <p>
        Cits – daudz skarbāks – stāsts ir pašvaldību cīņas ar privātīpašniekiem,
        kas klaji pārkāpj likumu un savus žogus aizbūvē līdz pašai ūdens malai.
        Tāds nebeidzamais stāsts – īpašnieks uzbūvē, pašvaldība liek nojaukt.
        Pašvaldībai ir tiesības tādu žogu nojaukt pašai un rēķinu par darbu likt
        apmaksāt privātīpašniekam.
      </p>
      <p>
        Tauvas joslas bezmaksas lietošana bez iepriekšējas saskaņošanas ar zemes
        īpašnieku ir paredzēta:
      </p>
      <ul>
        <li>kājāmgājējiem</li>
        <li>zivju resursu un ūdeņu uzraudzībai un izpētei;</li>
        <li>robežapsardzībai</li>
        <li>
          vides aizsardzības, ugunsdrošības un glābšanas pasākumu veikšanai.
        </li>
      </ul>
      <p>Pēc saskaņošanas ar zemes īpašnieku tauvas joslā ir atļauta:</p>
      <ul>
        <li>
          laivu un kuģu piestāšana (izņemot zvejas uzraudzības dienesta laivas
          un kuģus, ja tie izmantoti, pildot dienesta pienākumus), to izkraušana
          un pagaidu uzglabāšana;
        </li>
        <li>laivu un kuģu pārziemošana, būve un remonts;</li>
        <li>
          zvejnieku apmetņu ierīkošana, atpūta, zvejas rīku žāvēšana un citas ar
          zveju saistītas darbības;
        </li>
        <li>ūdenstūristu apmetņu ierīkošana</li>
      </ul>
      <p>
        Virszemes ūdensobjektu aizsargjoslas nosaka ūdenstilpēm, ūdenstecēm un
        mākslīgiem ūdensobjektiem, lai samazinātu piesārņojuma negatīvo ietekmi
        uz ūdens ekosistēmām, novērstu erozijas procesu attīstību, ierobežotu
        saimniecisko darbību applūstošajās teritorijās, kā arī saglabātu apvidum
        raksturīgo ainavu.
      </p>
      <p>
        Minimālie virszemes ūdensobjektu aizsargjoslu platumi tiek noteikti
        lauku apvidos (neatkarīgi no zemes kategorijas un īpašuma) Daugavai — ne
        mazāk kā 500 metrus plata josla katrā krastā/uz salām un pussalām —
        teritoriju plānojumos, bet ne mazāk kā 20 metrus plata josla.
      </p>
    </div>
  );
};

export default TowBarPageContent;
