import React from 'react';
import Moose from '../../assets/moose.svg';
import Family from '../../assets/family.svg';
import Map from '../../assets/karte.svg';
import './HomepageIntroSection.css';

const HomepageIntroSection = () => {
  return (
        <div className="HomepageIntro">
          <div className="HomepageIntroContainer">
            <div className="HomepageIntroContainerLeft">
              <div className="HomepageIntroContainerLeftUpper">
                <img alt="location" className="LocationIcon" src={ Moose } />
                <h3>Daba</h3>
                <img alt="location" className="LocationIcon" src={ Family } />
                <h3>Cilvēki</h3>
              </div>
              <h1>DOLES SALA</h1>
              <p>Doles sala ir bijusī lielākā sala Daugavas lejtecē, senās ārējās deltas rajonā. Mūsdienās Doles sala faktiski ir pussala, ko ar Daugavas kreiso krastu savieno uzbērts dambis, bet labo — spēkstacijas aizsprosts.</p>
              <p>Lielāko daļu salas aizņem plakans, nedaudz viļņots līdzenums ar absolūto augstumu 5 — 10 m virs jūras līmeņa. Salu divās daļās dala 120 — 250 m plata erozijas gultne Lebjava, pa kuru agrāk lielos palos ūdens no Ceļa Daugavas notecēja Sausajā Daugavā. Salā izveidots Doles salas dabas parks.</p>
            </div>
            <div className="HomepageIntroContainerMiddle">
              <img alt="Doles salas karte" src={ Map }></img>
            </div>
            <div className="HomepageIntroContainerRight">
              <div className='HomepageHomeContainerUpperLeftItem'></div>
              <div className='HomepageHomeContainerUpperLeftItem'></div>
              <div className='HomepageHomeContainerUpperLeftItem'></div>
              <div className='HomepageHomeContainerUpperLeftItem'></div>
            </div>
          </div>
        </div>
  )
}

export default HomepageIntroSection