import React from "react";
import "./CommunityValuesSafe.css";

const CommunityValuesSafe2 = () => {
  return (
    <div className="CommunityValuesSafe">
      <div>
        <div className="CommunityValuesSafeHeading">
          <h2>DROŠA VIDE</h2>
        </div>
        <p>
          Doles sala un tajā dzīvojošā kopiena ir viena no retajām vietām, kur
          ir droša un aizsargāta vide. Šī sala ir kļuvusi par piemēru tam, kā
          dzīvot saskaņā ar dabu, rūpējoties par ilgtspējīgu attīstību un visu
          iedzīvotāju drošību. Ilgtspējīga un droša dzīvesveida izveidošana ir
          iespējama, ja cilvēki strādā kopā, rūpējas par savu apkārtējo vidi un
          veido ilgtspējīgas attiecības ar dabu.
        </p>
      </div>
      <div className="CommunityValuesSafeImage">
        <img
          src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/values_drosa.png"  
          alt="Safe community"
        />
      </div>
      <div>
        <p>
          Droša vide ir viens no galvenajiem faktoriem, kas padara Doles salu un
          tās kopienu tik unikālu. Šajā vietā iedzīvotāji ir apņēmušies
          aizsargāt ainavu, dabiskos resursus un bioloģisko daudzveidību. Tieši
          šī vide sniedz iedzīvotājiem dzīves kvalitāti un iespēju baudīt
          mierīgu un harmonisku dzīvi.
        </p>
      </div>
      <div>
        <p>
          Lai nodrošinātu šo drošo vidi, Doles salas iedzīvotāji ir ieguldījuši
          daudz pūļu un resursu ekosistēmu aizsardzībā un ilgtspējīgā attīstībā.
          Viņi aktīvi piedalās vides izglītības un informēšanas pasākumos, lai
          veicinātu sabiedrības izpratni par vides aizsardzību.
        </p>
      </div>
      <div>
        <p>
          Šie centieni ir noveduši pie tā, ka Doles sala ir kļuvusi par
          paradigmu tam, kā cilvēki var saskaņot dzīvi ar dabu un saglabāt drošu
          vidi gan pašiem sev, gan nākamajām paaudzēm. Droša vide
          Doles salā ir ne tikai vērtība pašiem iedzīvotājiem, bet arī iedvesma
          sabiedrībai kopumā.
        </p>
      </div>
    </div>
  );
};

export default CommunityValuesSafe2;
