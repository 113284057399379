import React from "react";
import "./CommunityValuesExampl.css";

const CommunityValuesExampl = () => {
  return (
    <div className="CommunityValuesExampl">
      <div>
        <div className="CommunityValuesExamplHeading">
          <h2>PARAUGKOPIENA</h2>
        </div>
        <p>
          Doles sala, šī mazā, bet bagātā Latvijas sala, ir īpaša vieta, kur
          vērtība "Paugkopiena" ir dzimusi un ziedējusi kā saulespuķe Daugavas
          krastā. Doles salas iedzīvotāji ir lepni par savu kopienas garu un
          solidaritāti, un viņi augstu ciena vērtību, ko viņi sauc par
          "Paugkopienas" vērtību. Paugkopiena Doles salā nozīmē, ka katrs
          indivīds un ģimene, kas dzīvo šajā salā, ir paraugs citiem. Tas nozīmē
          ne tikai rūpēties par sevi un savējiem, bet arī piedalīties kopējās
          aktivitātēs un projektos, kas nes labumu visiem iedzīvotājiem. Doles
          salas iedzīvotāji vienmēr ir gatavi dalīties zināšanās, prasmēs un
          resursos ar citiem, lai uzlabotu kopējo labklājību un veicinātu salas
          attīstību.
        </p>
      </div>
      <div className="CommunityValuesExamplImage">
        <img
          src="https://s3.eu-north-1.amazonaws.com/doles-sala.lv/values_paraug.png"
          alt="Two elderly riding bicycls"
        />
      </div>
      <div>
        <p>
          Doles sala ir vieta, kurā rūpējas par apkārtējo vidi un dabas
          saglabāšanu, un šīs vērtības ietekmi var redzēt ilgtspējīgajā
          lauksaimniecībā un dabas aizsardzības projektos. Iedzīvotāji uzskata,
          ka viņu rīcība un uzvedība var kalpot kā paraugs citām kopienām, kas
          cenšas aizsargāt un veicināt vidi.
        </p>
      </div>
      <div>
        <p>
          Turklāt Doles sala ir pazīstama ar savu kultūras un mākslas izcilību.
          Iedzīvotāji šeit rūpējas par mākslinieciskām izpausmēm un veicina tās
          attīstību, piedāvājot izstādes, koncertus un citus pasākumus, lai
          dalītos ar savām radošajām darbībām un iedvesmotu citus.
        </p>
      </div>
      <div>
        <p>
          Doles sala ir ne tikai skaista un unikāla vieta Baltijas jūras krastā,
          bet arī vieta, kur "Paugkopiena" ir dzīvotspējīga vērtība, kas
          iedvesmo citus pielikt pūles un kļūt par labākiem pasaules pilsoņiem.
        </p>
      </div>
    </div>
  );
};

export default CommunityValuesExampl;
