import React from "react";
import "./BuildingRulesIntro.css";

const BuildingRulesIntro = () => {
  return (
    <div className="BuildingRulesContainer">
      <h1>DZĪVE UZ SALAS</h1>
      <p>
        Šī informatīvā tīmekļa vietnes sadaļa piedāvā būtisku informāciju par
        Doles salas apbūves noteikumiem. Doles sala ir unikāla teritorija, kuras
        ģeogrāfiskās un vides īpatnības rada īpašas prasības attiecībā uz
        būvniecību un attīstību šajā reģionā. Kopumā šī sadaļa ir vērtīgs
        resurss tiem, kuri plāno būvniecības projektus Doles salā, piedāvājot
        saprotamu informāciju un norādes, kā ievērot un pielāgoties vietējām
        apbūves prasībām, lai saglabātu un aizsargātu salas īpašo vidi.
      </p>
      <p>
        Doles salā ir šādas funkcionālās zonas un katrā no tām ir savi apbūves
        noteikumi:
      </p>
      <ul>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">1</div>
          </div>
          Lauksaimniecības teritorijas
        </li>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">2</div>
          </div>
          Mežu teritorijas
        </li>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">3</div>
          </div>
          Vasarnīcu apbūves teritorijas
        </li>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">4</div>
          </div>
          Rūpnieciskās apbūves teritorijas
        </li>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">5</div>
          </div>
          Tehniskās apbūves teritorijas
        </li>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">6</div>
          </div>
          Dabas un apstādījumu teritorijas
        </li>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">7</div>
          </div>
          Rūpnieciskās un dzīvojamās teritorijas robežjoslas
        </li>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">8</div>
          </div>
          Transporta infrastruktūras teritorijas
        </li>
        <li className="BuildingRulesContainerList">
          <div>
            <div className="BuildingRulesContainerListItem">9</div>
          </div>
          Ūdeņu teritorijas
        </li>
      </ul>
      <p className="BuildingRulesSectionParagraphImportant">
        Lai saņemtu jaunāko un visprecīzāko informāciju par apbūves noteikumiem,
        vienmēr ir ieteicams pārbaudīt Salaspils novada mājas lapu vai
        sazināties ar attiecīgajām iestādēm.
      </p>
    </div>
  );
};

export default BuildingRulesIntro;
