import React, {useState} from 'react';
import './BuildingRulesSummerhouse.css';
import Shop from '../../assets/Shop.svg';
import CoffeeShop from '../../assets/CoffeeShop.svg';
import Outdoor from '../../assets/Outdoor.svg';
import SummerHouse from '../../assets/SummerHouse.svg';
import Arrow from '../../assets/ArrowIcon.svg';

const BuildingRulesSummerhouse = () => {
  const [isContentVisible, setContentVisible] = useState(false);
  const [rotate, setRotate] = useState(0);

  const toggleContentVisibility = () => {
    setContentVisible(!isContentVisible);
    setRotate(rotate === 0 ? 90 : 0);
  };

  const arrowStyle = {
    transform: `rotate(${rotate}deg)`,
    transition: "transform 1s ease",
  };

  return (
    <div>
      <div className="BuildingRulesSectionHeading__Summerhouse"
      onClick={toggleContentVisibility}>
        <h2>Vasarnīcu apbūves teritoriju apbūves noteikumi</h2>
        <img src={Arrow} alt="arrow" style={arrowStyle} />
      </div>
      <div className={`BuildingRulesSectionParagraphWrapper__Summerhouse ${
          isContentVisible ? "visible" : ""
        }`}
      >
        <p className="BuildingRulesSectionParagraphBold">
          Vasarnīcu apbūves teritorijā atļauta šādu ēku būvniecība:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <div className="BuildingRulesSectionParagraphPart">
            <img src={SummerHouse} alt="Road icon" />
            <p>
              Sezonālai dzīvošanai vasaras periodā paredzēta viendzīvokļa
              dzīvojamā māja
            </p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Shop} alt="Road icon" />
            <p>
              Viena mazumtirdzniecības ēka, izņemot degvielas uzpildes stacija
              un gāzes uzpildes stacija
            </p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={CoffeeShop} alt="Road icon" />
            <p>Viena restorāna, kafejnīcas vai bāra ēka</p>
          </div>
          <div className="BuildingRulesSectionParagraphPart">
            <img src={Outdoor} alt="Latern and chair icon" />
            <p>
              Viena vai vairākas būves, kas ir saistītas ar publiskās ārtelpas
              labiekārtošanu
            </p>
          </div>
        </div>
        <p className="BuildingRulesSectionParagraphBold">
          Vasarnīcu apbūves teritorijā ievēro šādas apbūves prasības:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <p>Jauni veidojamās zemes platība ir vismaz 600 m2</p>
          <p>Maksimālais stāvu skaits ir 2 stāvi</p>
          <p>Maksimālais pagrabstāvu vai cokolstāvu skaits ir 1 stāvs</p>
          <p>Palīgēkas stāvu skaits ir 1 stāvs</p>
          <p>
            Zemes vienības maksimālais apbūves blīvums ir līdz 20% un līdz 15%
            no stūra zemes vienības platības
          </p>
          <p>
            Ēkas, kuru fasādēs ir logi, būvē ne tuvāk par 4 metriem no zemes
            vienības sānu vai aizmugures robežām
          </p>
          <p>
            Autostāvvietas izvieto priekšpagalmā, sānu pagalmā vai aizmugures
            pagalmā, vai arī to var izvietot uz piebraucamā ceļa. Ja
            autostāvvietas izvietotas priekšpagalmā, to platība nedrīkst
            pārsniegt 25 % no priekšpagalma platības.
          </p>
        </div>
        <p className="BuildingRulesSectionParagraphBold">
          Prasības žogiem, kuri izvietoti gar ielu:
        </p>
        <div className="BuildingRulesSectionParagraph">
          <p>Augstums līdz 1 metram, ja žogs nav caurredzams</p>
          <p>Augstums līdz 1,40 metriem, ja žoga caurredzamība ir 30%</p>
          <p>Augstums līdz 1,60 metriem, ja žoga caurredzamība ir 50%</p>
          <p>Augstums līdz 1.80 metriem, ja žoga caurredzamība pārsniedz 80%</p>
        </div>
      </div>
    </div>
  );
};

export default BuildingRulesSummerhouse;
